.uil-ring {
}

.iosBugFixCaret {
  position: fixed;
  width: 100%;
}

.popup-overlay {
  background-color: rgba(0, 0, 0, 0.54);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.eating-age-grid {
  display: grid;
  grid-template-columns: 250px 250px;
  grid-gap: 32px;
  margin-bottom: 64px;
  justify-content: center;
}

.ea-block {
  height: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9c5e5;
  border-radius: 23px;
  color: white;
  font-size: 36px;
  font-weight: 600;
  cursor: pointer;
}

.ea-block-active {
  background-color: #924e9f;
}

.my-lunches-page,
.browse-page,
.profile-page,
.discover-page,
.explore-food-page,
.settings-page,
.boutique-page {
  padding-top: 45px;
  background-color: #ffffff;
  min-height: 100vh;
}

.browse-page h1 {
  margin-left: 10px;
}

.browse-page-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 50px;
  background-color: white;
  z-index: 9998;
}

.challenges-page-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 50px;
  background-color: white;
  z-index: 9998;
  margin-bottom: 15px;
}

.title-browse {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.browse-boutique-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9998;
}

.browse-boutique-header-title {
  color: #737373;
  font-size: 20px;
  font-weight: 400;
}

.browse-blog-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9998;
}

.old-feed {
  margin-left: 10%;
}

.explore-food-page h1 {
  margin-bottom: 25px;
}

.lunch-thumb-group {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px;
  grid-gap: 45px 30px;
  padding-bottom: 80px;
  min-height: 600px;
  z-index: 9997;
}

.lunch-thumb-group-display-only-smartphone {
  display: none;
}

.lunch-thumb-group-display-only-laptop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.lunch-grid-group-profile {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-gap: 10px 10px;
  padding-bottom: 10px;
  min-height: 300px;
  z-index: 9997;
  justify-content: center;
}

.lunch-grid-image {
  width: 100%;
  height: 100px;
  border-radius: 23px;
  cursor: pointer;
  position: relative;
  outline: none;
  background-position: top center;
  background-size: cover;
  display: block;
}

.lunch-thumb {
  width: 100%;
  height: 200px;
  border-radius: 23px;
  cursor: pointer;
  position: relative;
  outline: none;
  background-position: top center;
  background-size: cover;
  display: block;
}

.lunch-thumb-for-grid {
  width: 100%;
  height: 96px;
  cursor: pointer;
  position: relative;
  outline: none;
  background-position: top center;
  background-size: cover;
  display: block;
}

.lunch-thumb-landing {
  width: 100%;
  height: 200px;
  cursor: pointer;
  position: relative;
  outline: none;
  background-position: top center;
  background-size: cover;
  display: block;
}

.lunch-thumb-for-profile {
  width: 100%;
  height: 200px;
  border-radius: 23px;
  border: 2px solid #f5f5f5;
  cursor: pointer;
  position: relative;
  outline: none;
  background-position: top center;
  background-size: cover;
  display: block;
}

.lunch-thumb-for-profile-grid {
  width: 100px;
  height: 100px;
  border-radius: 23px;
  border: 2px solid #f5f5f5;
  cursor: pointer;
  position: relative;
  outline: none;
  background-position: top center;
  background-size: cover;
  display: block;
}

.lunch-thumb-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top center;
  border-radius: 23px;
  outline: none;
}

.lunch-thumb-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 3, 16, 0.51);
  border-radius: 23px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.lunch-thumb-hover-delete {
  position: absolute;
  top: 6px;
  right: 6px;
  color: white;
  cursor: pointer;
  background-color: #b1b1b1;
  border-radius: 50%;
  padding: 6px;
}

.lunch-thumb:hover .lunch-thumb-hover {
  display: flex;
}

.menu .lunch-thumb-hover {
  border-radius: 0;
}
.category-ideas {
  border-radius: 0;
}

.category-boutique-items {
  border-radius: 0;
}

.category-giveaway-items {
  border-radius: 0;
}

.category-blog-items {
  border-radius: 0;
}

.category-ideas-landing {
  border-radius: 0;
}

.food-hover-name {
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.lunch-modal,
.shopping-modal,
.follow-modal,
.mailchimp-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.78);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.mailchimp-modal {
  border-radius: 4px;
  border: none;
}

.lunch-modal .close-lunch,
.close-modal,
.close-follow {
  fill: #b9b8b8;
  stroke: black;
  top: 75px;
  right: 51px;
  font-size: 48px;
}

.close-modal-list {
  fill: #b9b8b8;
  font-size: 48px;
  margin-left: 15px;
  justify-content: right;
  align-items: right;
}

.lunch-popup {
  width: 970px;
  height: 485px;
  display: flex;
  max-width: 90%;
  border-radius: 30px;
  overflow: hidden;
  margin: 51px auto 10vh;
}

.lunch-modal .lunch-popup {
  margin-top: 0;
  margin-bottom: 0;
  min-height: none;
}

.lunch-left {
  width: 485px;
  height: 485px;
  background-size: cover;
  background-position: top center;
  background-color: white;
}

.lunch-right {
  flex: 1;
  height: 485px;
  background-color: white;
  padding: 10px 42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lunch-user-image {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: top center;
  background-image: url("/images/user.png");
  border-radius: 50%;
}

.lunch-modal-header {
  display: flex;
  align-items: center;
}

.lunch-user-details {
  margin-left: 14px;
}

.lunch-byline,
.lunch-byline a {
  font-size: 14px;
  font-weight: 500;
}

.lunch-byline a {
  color: #934fa0;
}

.lunch-byline {
  color: #b1b1b1;
}

.lunch-content {
  margin-top: 20px;
}

.lunch-link-recipe {
  margin-top: 8px;
}

.lunch-link-recipe a {
  font-size: 10px;
}

.partners-content {
  background-color: #ffffff;
}

.lunch-content-options {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 6px;
  height: 30px;
}

.profile-content-options-display-smartphone {
  display: none;
}

.boutique-content-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding-top: 8px;
  padding-bottom: 7px;
  height: 30px;
  background-color: #f6eff7;
  width: 100%;
  gap: 40px;
}

.partners-content-options {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 6px;
  padding-top: 15px;
  height: 30px;
  background-color: #f6eff7;
}

.partners-button-free {
  background-color: white;
  color: #934ea0;
  border: 1px solid #934ea0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  font-family: "GT America Standard", sans-serif;
  letter-spacing: 1px;
  border-radius: 4px;
  max-width: 100%;
}

.partners-button-premium {
  background-color: #934ea0;
  color: white;
  border: 1px solid #934ea0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  font-family: "GT America Standard", sans-serif;
  letter-spacing: 1px;
  border-radius: 4px;
  max-width: 100%;
}

.partners-button-free:hover {
  background-color: #934ea0;
  color: white;
}

.partners-button-premium:hover {
  background-color: #cb6ce6;
  color: white;
}

.partners-contact-section {
  background-color: #f6eff7;
  width: 100%;
  padding: 40px 0 65px;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.partners-contact-section h1 {
  margin-bottom: 24px;
}

.infocard-content {
  margin-top: 10px;
  align-content: center;
}

.infocard-landing-image {
  background-size: cover;
  background-position: top center;
  width: 250px;
  height: 250px;
}

.infocard-boutique-image {
  background-size: cover;
  background-position: top center;
  width: 200px;
  height: 80px;
}

.infocard-blog-image {
  background-size: cover;
  background-position: top center;
  width: 200px;
  height: 80px;
}

.upgradecard-list {
  display: block;
  list-style-type: none;
  list-style-image: url("/icons/check.png");
}

.lunch-content-options h6 {
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.partners-content-options h3 {
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.boutique-content-options h3 {
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.lunch-content-option-active {
  border-bottom: 3px solid black;
}

.partners-content-option-active {
  border-bottom: 3px solid black;
}

.lunch-main-info {
  padding: 16px 0;
  height: 180px;
  box-sizing: border-box;
  line-height: 22px;
  overflow-y: scroll;
}

.partners-main-info {
  height: 100%;
}

.footer-features-list {
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
align-items: flex-start;
align-content: flex-start;
padding-top: 25px;;
gap: 10px 30px;
}

.lunch-actions {
  border-top: 1px solid #e0e0e0;
  height: 60px;
  display: flex;
  padding: 0 12px;
  justify-content: space-between;
  align-items: center;
}

.like-and-share svg {
  margin-right: 10px;
  cursor: pointer;
}

.search-text-option {
  font-size: 14px;
}

.sharing-block {
  position: relative;
}

.sharing-options {
  position: absolute;
  bottom: 40px;
  width: 200px;
  text-align: center;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  left: -75px;
  background-color: white;
  padding: 12px 0;
  left: 50%;
  transform: translateX(-50%);
}

.sharing-options:after,
.sharing-options:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.sharing-options:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 5px;
  margin-left: -5px;
}
.sharing-options:before {
  border-color: rgba(210, 210, 210, 0);
  border-top-color: #d2d2d2;
  border-width: 6px;
  margin-left: -6px;
}
.like-and-share {
  display: flex;
  align-items: center;
}

.sharing-options a {
  font-size: 14px;
  display: block;
  color: #924e9f;
  cursor: pointer;
}

.save-button {
  border: 2px solid #272727;
  padding: 8px 8px;
  border-radius: 22.5px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.save-button:hover {
  background-color: #272727;
  color: white;
}

.save-button svg {
  margin-left: 6px;
  cursor: pointer;
}

.saved-button {
  background-color: #272727;
  color: white;
}

.calendar-icon-save-btn {
  size: 18px;
  margin-right: 5px;
}

.buy-button {
  border: 2px solid #783c84;
  background-color: #934fa0;
  color: white;
  padding: 8px 18px;
  margin-left: 10px;
  border-radius: 22.5px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.buy-button-link {
  color: white;
}

.buy-button:hover {
  background-color: #783c84;
  color: white;
}

.buy-button svg {
  margin-left: 6px;
  cursor: pointer;
}

.edit-button {
  border: 2px solid #272727;
  padding: 8px 18px;
  margin-left: 10px;
  border-radius: 22.5px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #272727;
  color: white;
}

.edit-button svg {
  margin-left: 6px;
  cursor: pointer;
}

.right-lunch-actions {
  display: flex;
  align-items: center;
}

.delete-lunch {
  font-size: 14px;
  color: #924e9f;
  margin-right: 14px;
  cursor: pointer;
}

.like-info {
  border-top: 1px solid #e0e0e0;
  height: 45px;
  display: flex;
  padding: 0 12px;
  align-items: center;
  font-size: 12px;
}

.likes-and-saves {
  font-weight: 500;
}

.likes-and-saves strong {
  font-weight: 500;
  color: #1e1d1e;
}

.likes-and-saves span {
  color: #b2b2b2;
}

.likes-and-saves span:first-of-type {
  margin-right: 13px;
}

.like-separator {
  height: 16px;
  background-color: #d6d6d6;
  width: 1px;
  margin: 0 12px;
}

.like-photos {
  display: flex;
}

.like-photos .like-photo:nth-child(n + 5) {
  display: none;
}

.like-photo {
  width: 22px;
  height: 22px;
  background-size: cover;
  margin-right: 5px;
  border-radius: 50%;
  background-image: url("/images/user.png");
}

.profile-page {
  margin-top: 25px;
}

.profile-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.profile-image-forlaptop {
  width: 175px;
  height: 175px;
  background-size: cover;
  background-position: top center;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  background-image: url("/images/user.png");
}

.profile-image-forsmartphone {
  display: none;
}

.profile-user-verifiedbadge {
  width: 25px;
  height: 25px;
  margin-left: 8px;
  background-size: cover;
  background-position: top center;
  background-image: url("/images/verifiedbadge_school.png");
}

.profile-name-plus-link {
  display: flex;
  align-items: center;
}

.profile-button {
  border-radius: 4px;
  cursor: pointer;
  padding: 3px 25px;
  margin-left: 48px;
  font-size: 13px;
  font-weight: 500;
}

.edit-profile-button {
  border: 1px solid black;
}

.follow-button {
  background-color: #934fa0;
  color: white;
}

.unfollow-button {
  border: 1px solid #272727;
}

.profile-other-details {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.country-name {
  display: inline;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
}

.favitems-count {
  font-weight: 600;
  color: #934fa0;
  display: inline;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
}

.profile-lunchbox {
  display: inline;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
}

.profile-stats {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.profile-stats h2 {
  color: #934ea0;
  font-weight: bold;
  cursor: pointer;
}

.profile-stats h2:first-of-type {
  cursor: default;
}

.profile-stats-counter {
  margin-right: 15px;
}

.profile-social {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.profile-social-icon {
  display: inline;
  font-size: 18px;
  line-height: 24px;
  margin-right: 15px;
}

.profile-btn-editorfollow-phones {
  display: none;
}

.profile-lunchbox h5 {
  margin-right: 24px;
  cursor: pointer;
}

.profile-lunchbox h5:first-of-type {
  cursor: default;
}

.profile-lunches {
  margin-top: 80px;
}

.profile-details {
  margin-left: 15px;
}

.lunch-menu-adder {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 38px;
  width: 38px;
  transition: height 100ms ease-in-out, width 200ms ease;
  overflow: hidden;
}

.lunch-menu-adder .toggler-text {
  display: none;
  user-select: none;
}

.lunch-menu-adder:hover {
  width: 155px;
}

.adder-open {
  height: 126px;
  width: 155px;
}

.adder-open .toggler-text {
  display: inline;
}

.plus-menu {
  color: white;
  transition: transform 200ms ease;
}

.lunch-menu-toggler {
  background-color: #c4d72c;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  transition: width 200ms ease;
}

.lunch-menu-toggler:after,
.adder-open .toggler-text {
  display: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
  margin-left: 16px;
  max-height: 14px;
  overflow: hidden;
}

.lunch-menu-adder:hover .lunch-menu-toggler,
.adder-open .lunch-menu-toggler {
  width: 155px;
  border-radius: 15px;
  align-items: center;
  color: white;
}

.lunch-menu-toggler:hover:after {
  display: inline;
  content: "Add to Plan";
}

.adder-open .lunch-menu-toggler:hover:after {
  display: none;
}

.adder-open .lunch-menu-toggler {
  background-color: black;
  color: white;
}

.adder-open .plus-menu {
  transform: rotate(-45deg);
}

.adder-open .toggler-text {
  display: inherit;
}

.toggler-calendar {
  height: 40px;
  width: 155px;
  margin-top: 4px;
  display: flex;
}

.toggler-calendar-search {
  height: 40px;
  width: 155px;
  margin-top: 4px;
  display: flex;
  border: solid 1px #b9b8b8;
}

.toggler-picker {
  width: 105px;
  display: flex;
  padding: 0;
  border-radius: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.search-date-box {
  width: 230px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 11px;
}

.togg-icon-h {
  width: 55px;
  background-color: #c4d72c;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.submit-menu {
  height: 40px;
  width: 155px;
  margin-top: 4px;
  background-color: #c4d72c;
  border-radius: 4px;
  color: white;
  border: none;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
}

.my-lunches-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.my-menus {
  margin-top: 10px;
}

.my-menus-today-btn {
  border-radius: 2px;
  margin-left: 2px;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  max-width: 100%;
  cursor: pointer;
  position: relative;
  min-height: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: solid 1px #dcc6e0;
}

.my-menus-today-btn-text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  transition-property: opacity, transform;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;
}

.my-menus-today-btn:hover {
  background-color: #f6eff7;
}

.my-categories-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.my-categories-seeall a {
  color: #934fa0;
}

.my-categories {
  margin-top: 7px;
  overflow-x: scroll;
}

.my-categories-explore-search-tip-boutique-laptop {
  margin-top: 25px;
  overflow-x: scroll;
  display: flex;
  justify-content: center;
}



.my-categories-explore-search-tip-boutique-smartphone {
  margin-top: 25px;
  overflow-x: scroll;
  background-color: #FFFFFF;
}

.my-categories-header-smartphone {
  background-color: #FFFFFF;
}

.my-categories-landing {
  margin-top: 15px;
  overflow-x: scroll;
  background-color: #f6eff7;
}

.my-menus-header {
  /* position: relative; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* .my-menus-header {
  position: relative;
  display: inline-flex;
  align-items: center;
} */
/* .my-categories-header {
  display: flex;
  justify-content: center;
} */

/* .my-menu-dates-group {
  text-align: center;
  justify-content: center;
} */


.my-categories-header {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.my-categories-header-landing {
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: #f6eff7;
}

.menu-action-icons {
  /* display: inline-flex; */
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin-top: 10px;
}

.menu-arrow {
  fill: #dcc6e0;
  font-size: 24px;
  cursor: pointer;
}

/* .left-menu-arrow {
  position: absolute;
  left: -18px;
  transform: translateX(-50%);
} */

.left-menu-arrow {
  position: relative;
  left: unset;
  transform: unset;
  margin-right: 12px;
}

.right-menu-arrow {
  margin-left: 12px;
}

.span-btn-upload-smartphone{
  margin-left: 5px;
  color: #ffffff;
  font-size: 16px;
}

.print-icon,
.cart-icon,
.stat-icon,
.medic-icon {
  font-size: 26px;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
}

.heart-icon {
  font-size: 26px;
  margin-left: 5px;
  cursor: pointer;
}

.menu {
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 280px;
  max-width: 100%;
  overflow-x: visible;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.category-ideas {
  margin: 5px 0 12px;
  min-height: 280px;
}

.category-boutique-items {
  margin: 5px 0;
  min-height: 125px;
}

.category-giveaway-items {
  margin: 5px 0;
  min-height: 125px;
}

.giveaway-winnernumber {
  font-weight: bold;
  color: #389477;
}

.giveaway-highlightsponsor {
  font-weight: bold;
}

.category-blog-items {
  margin: 5px 0;
  min-height: 125px;
}

.category-ideas-landing {
  margin: 5px 0 12px;
  min-height: 215px;
  background-color: #FFFFFF;
}

.category-ideas-landing-smartphone {
  margin: 5px 0 12px;
  min-height: 255px;
  background-color: #FFFFFF;
  overflow-x: scroll;
}

.menu-dates {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px;
  background-color: white;
  height: 1px;
  border-top-left-radius: 31px;
  border-top-right-radius: 31px;
  grid-gap: 0px 3px;
  width: 1012px;
}

.landing-explore-grid {
  display: grid;
  grid-template-columns: 250px 250px 250px 250px 250px 250px 250px 250px 250px 250px 250px 250px 250px 250px 250px;
  background-color: #FFFFFF;
  height: 250px;
  border-top-left-radius: 31px;
  border-top-right-radius: 31px;
  grid-gap: 0px 20px;
  width: 4030px;
  background-size: cover;
  background-position: top center;
}

.boutique-explore-grid {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px;
  background-color: white;
  height: 125px;
  border-top-left-radius: 31px;
  border-top-right-radius: 31px;
  grid-gap: 0px 10px;
  width: 2720px;
  background-size: cover;
  background-position: top center;
}

.blog-explore-grid {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px;
  background-color: white;
  height: 125px;
  border-top-left-radius: 31px;
  border-top-right-radius: 31px;
  grid-gap: 0px 10px;
  width: 1670px;
  background-size: cover;
  background-position: top center;
}

.feed-explore-grid {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px;
  background-color: white;
  height: 125px;
  border-top-left-radius: 31px;
  border-top-right-radius: 31px;
  grid-gap: 0px 10px;
  width: 1040px;
  background-size: cover;
  background-position: top center;
}

.ideas-header {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px;
  background-color: white;
  height: 45px;
  border-top-left-radius: 31px;
  border-top-right-radius: 31px;
  grid-gap: 0px 10px;
  width: 1012px;
}

.ideas-footer {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px;
  background-color: white;
  height: 45px;
  border-top-left-radius: 31px;
  border-top-right-radius: 31px;
  grid-gap: 0px 10px;
  width: 1012px;
}

.menu-dates span {
  font-size: 14px;
  font-family: "GT America Standard", sans-serif;
  display: flex;
  align-items: center;
  padding-left: 5px;
  font-weight: 500;
  color: #b1b1bb;
}

.my-lunches-titleandcounter {
  width: 300px;
}

.ideas-header span {
  font-size: 16px;
  font-family: "GT America Standard", sans-serif;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
  border-width: 1px;
  border-style: solid;
  border-color: #f5f5f5;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.ideas-footer span {
  font-size: 16px;
  font-family: "GT America Standard", sans-serif;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
  border-width: 1px;
  border-style: solid;
  border-color: #f5f5f5;
}

.menu-lunches {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px;
  background-color: #ffffff;
  grid-gap: 0px 3px;
  border-top: 3px solid #ffffff;
}

.ideas-lunches {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px;
  background-color: #ffffff;
  grid-gap: 0px 10px;
  border-top: 0px solid #ffffff;
}

.ideas-lunches-landing {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px;
  background-color: #f6eff7;
  grid-gap: 0px 10px;
  border-top: 0px solid #f6eff7;
}

.menu-lunches .lunch-thumb {
  height: 200px;
  border-radius: 0;
  background-color: #ffffff;
  background-size: cover;
  background-position: top center;
}

.ideas-lunches .ideas-thumb {
  height: 200px;
  border-radius: 0;
  background-color: #ffffff;
  background-size: cover;
  background-position: top center;
}

.lunch-thumb-box {
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-height: 295px;
  border-width: 2px;
  border-style: solid;
  border-color: #f5f5f5;
}

.lunch-thumb-box-for-grid {
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-height: 180px;
  border-width: 2px;
  border-style: solid;
  border-color: #f5f5f5;
}

.ltb-user-header {
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 13px;
  overflow: hidden;
  border-bottom: 1px solid #f5f5f5;
}

.ltb-user-header-for-grid {
  height: 48px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
  overflow: hidden;
  border-bottom: 1px solid #f5f5f5;
}

.ltb-user-pic {
  width: 30px;
  height: 30px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  margin-right: 8px;
  background-size: cover;
  background-position: top center;
  background-image: url("/images/user.png");
}

.ltb-user-pic-for-grid {
  width: 30px;
  height: 30px;
  flex: none;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  margin-right: 8px;
  background-size: cover;
  background-position: top center;
  background-image: url("/images/user.png");
}

.ltb-user-link {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 135px;
}

.ltb-user-verifiedbadge {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  background-size: cover;
  background-position: top center;
  background-image: url("/images/verifiedbadge_school.png");
}

.lunch-thumb-box .lunch-thumb,
.lunch-thumb-box .lunch-thumb-hover {
  border-radius: 0;
}

.ltb-lunch-actions {
  height: 41px;
  display: flex;
  padding: 0 21px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f5f5f5;
}

.ltb-lunch-actions-for-grid {
  height: 36px;
  display: flex;
  padding: 0 11px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #f5f5f5;
}

.lunch-thumb-group .like-and-share {
  display: flex;
  align-items: center;
}

.lunch-thumb-group .like-and-share svg,
.latest-lunches-plus-reviews .like-and-share svg {
  height: 16px;
  margin-right: 10px;
}

.follow-friends {
  margin-top: 36px;
  color: #b9b9b9;
}

.bookmark-box svg {
  height: 16px;
  cursor: pointer;
}

.lunch-placeholder {
  height: 200px;
  border-radius: 0;
  border: 1px solid #e9e9e9;
  background-color: #f5f5f5;
  font-size: 24px;
  display: flex;
  align-items: center;
  padding: 0 42px;
  color: #b1b1b1;
  line-height: 29px;
  font-weight: 500;
}

.ideas-placeholder {
  height: 200px;
  border-radius: 0;
  border: 1px solid #e9e9e9;
  background-color: #f5f5f5;
  font-size: 24px;
  display: flex;
  align-items: center;
  padding: 0 42px;
  color: #b1b1b1;
  line-height: 29px;
  font-weight: 500;
}

.my-lunches {
  padding-bottom: 200px;
}

.my-lunches-filters,
.browse-filters,
.blog-posts-filters,
.blog-filters {
  margin-left: 42px;
  display: flex;
  flex-wrap: wrap;
}

.my-boutique-filters {
  margin-left: 42px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.my-blog-filters {
  margin-left: 42px;
  display: flex;
  flex-wrap: wrap;
}

.my-feed-features {
  margin-left: 42px;
  display: flex;
  flex-wrap: wrap;
}

.my-lunches-filters,
.blog-posts-filters {
  margin-left: 0;
  margin-top: 24px;
}

.my-boutique-filters {
  margin-left: 0;
  margin-top: 5px;
}

.my-blog-filters {
  margin-left: 0;
  margin-top: 5px;
}

.my-feed-features {
  margin-left: 0;
  margin-top: 15px;
}

.my-lunches-header h2 {
  display: block;
}

.my-lunches-filters h5,
.browse-filters h5,
.blog-posts-filters h5,
.blog-filters h5 {
  margin-right: 8px;
  padding: 10px 16px;
  color: #b9b9b9;
  cursor: pointer;
  border-radius: 23px;
  display: block;
}

.my-boutique-filters h5 {
  margin-right: 8px;
  padding: 10px 16px;
  color: #b9b9b9;
  cursor: pointer;
  border-radius: 23px;
  display: block;
}

.my-blog-filters h5 {
  margin-right: 8px;
  padding: 10px 16px;
  color: #b9b9b9;
  cursor: pointer;
  border-radius: 23px;
  display: block;
}

.my-feed-features h5 {
  margin-right: 8px;
  padding: 10px 16px;
  color: #b9b9b9;
  cursor: pointer;
  border-radius: 23px;
  display: block;
}

.active-filter {
  color: #272727 !important;
  background-color: #dcc6e0;
}

.more-dropdown .active-filter {
  background-color: white;
}

.more-dropdown {
  position: absolute;
  background: #ffffff;
  text-align: center;
  padding: 24px 0;
  border-radius: 23px;
  z-index: 22;
  left: 50%;
  margin-top: 8px;
  width: 300px;
  transform: translateX(-50%);
}

.more-dropdown:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 12px;
  margin-left: -12px;
}

.more-dropdown h5 {
  margin-right: 0;
  padding: 12px 24px;
}

.more-option {
  position: relative;
  max-width: 100%;
  cursor: pointer;
}

/* .my-menu-dates-group {
  display: flex;
  align-items: center;
} */

.my-menu-dates-group {
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.close-modal,
.close-follow,
.close-modal-list {
  position: absolute;
  cursor: pointer;
}

.shopping-list {
  max-height: 90%;
  width: 660px;
  max-width: 100%;
  background-color: #ffffff;
  overflow: scroll;
}

.shopping-list-header {
  height: 95px;
  background-color: #272727;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
}

.shopping-list-header h3 {
  color: white;
}

.favlunchboxitems-list-header {
  height: 95px;
  background-color: #272727;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
}

.favlunchboxitems-list-header h3 {
  color: white;
}

.mailchimp-popup-header {
  height: 70px;
  align-items: center;
  text-align: center;
  vertical-align: center;
  line-height: 30px;
  padding: 0 20px;
}

.mailchimp-popup-header h4 {
  color: #272727;
}

.mailchimp-modal-privacypolicy {
  height: 10px;
  margin-top: 15px;
  align-items: center;
  text-align: center;
}

.mailchimp-style-privacypolicy {
  font-size: 10px;
}

.mailchimp-modal-errormsg {
  height: 10px;
  margin-top: 3px;
  align-items: center;
  text-align: center;
}

.mailchimp-style-errormsg {
  font-size: 10px;
  color: red;
}


.mailchimp-modal-infomsg {
  height: 10px;
  margin-top: 3px;
  align-items: center;
  text-align: center;
}

.mailchimp-style-infomsg {
  font-size: 11px;
  color: green;
}

.mailchimp-getemail {
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  text-align: center;
}

.mailchimp-getemail input {
  width: 250px;
  align-items: center;
  text-align: center;
}

.mailchimp-hiddenfield {
  display: none;
}

.mailchimp-submit-btn {
  align-items: center;
  justify-content: center;
}

.no-lunches {
  text-align: center;
  padding: 32px 0 24px;
}

.grocery-list-icons svg {
  fill: #b1b1b1;
  margin-left: 14px;
  font-size: 28px;
  cursor: pointer;
}

.grocery-list-icons {
  margin-right: 35px;
}

.close {
  height: 20px;
  align-items: right;
  justify-content: right;
}

.close svg {
  fill: #b1b1b1;
  margin-left: 14px;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 28px;
  cursor: pointer;
  position: relative;
  float: right;
  align-items: right;
  justify-content: right;
}

.favlunchboxitems-list-icons svg {
  fill: #b1b1b1;
  margin-left: 14px;
  font-size: 28px;
  cursor: pointer;
}

.favlunchboxitems-list-icons {
  margin-right: 35px;
  margin-bottom: 25px;
}

.food-group-title {
  padding: 0 20px;
  height: 50px;
  align-items: center;
  display: flex;
  background-color: rgba(162, 209, 95, 0.12);
}

.favlunchboxitems-group-title {
  padding: 0 20px;
  height: 50px;
  align-items: center;
  display: flex;
  background-color: rgba(162, 209, 95, 0.12);
}

.lunchboxitems-group-title {
  padding: 0 20px;
  height: 50px;
  align-items: center;
  display: flex;
  background-color: rgba(162, 209, 95, 0.12);
}

.food-list-item {
  height: 65px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  box-sizing: border-box;
  position: relative;
}

.favlunchboxitems-list-item {
  height: 65px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  box-sizing: border-box;
  position: relative;
}

.shop-now {
  position: absolute;
  right: 20px;
  background-color: #ff9900;
  font-size: 14px;
  border-radius: 4px;
  padding: 12px 16px;
  color: white;
  cursor: pointer;
}

.parent-container-foods-and-shop-now {
  display: flex;
  justify-content: space-between;
  height: 24px;
  align-items: center;
  margin-bottom: 5px;
}

.detail-food-link {
  color: #934fa0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}

.shop-now-amazon {
  right: 20px;
  background-color: #ff9900;
  font-size: 10px;
  border-radius: 2px;
  padding-left: 8px;
  padding-right: 3px;
  color: white;
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
  vertical-align: middle;
}

.shop-now-amazon img {
  vertical-align: middle;
}

.food-image {
  height: 50px;
  width: 50px;
  background-size: cover;
  background-position: top center;
}

.favlunchboxitems-image {
  height: 50px;
  width: 50px;
  background-size: cover;
  background-position: top center;
}

.food-name {
  margin-left: 25px;
}

.favlunchboxitems-name {
  margin-left: 25px;
}

.foodgroup-name {
  margin-left: 25px;
  width: 60px;
  justify-content: center;
  align-items: center;
}

.food-group-section .food-list-item:last-of-type {
  border-bottom: none;
}

.favlunchboxitems-group-section .favlunchboxitems-list-item:last-of-type {
  border-bottom: none;
}

.lunchboxitems-group-section {
  border-bottom: none;
}

.shop-now-section {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.shop-now-button {
  width: 90%;
  cursor: pointer;
  background-color: #934fa0;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.shop-now-button:hover {
  background-color: #783c84;
}

.amazon-disclosure,
.amazon-disclosure a {
  font-size: 13px;
  margin: 24px 0;
  text-align: center;
  width: 80%;
  color: #a0a0a0;
}

.amazon-disclosure-lunch,
.amazon-disclosure-lunch a {
  font-size: 9px;
  text-align: center;
  width: 90%;
  color: #a0a0a0;
  line-height: normal;
}

.amazon-grocery-region {
  font-size: 12px;
  text-align: center;
  width: 90%;
  color: #a0a0a0;
  line-height: normal;
}

.shop-now-button img {
  height: 28px;
  margin-left: 10px;
}

.search-results-box {
  position: absolute;
  width: 90%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 1px #b7b7b7;
  z-index: 999;
  max-height: 200px;
  left: 0;
  right: 0;
  margin: 15px auto 0;
  overflow: scroll;
  min-width: 300px;
}

.search-result-item {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  cursor: pointer;
  border-bottom: 1px solid #eeeeee;
  box-sizing: border-box;
}

.search-results-box .search-result-item:last-of-type {
  border-bottom: none;
}

.search-result-item:hover {
  background-color: #eeeeee;
}

.search-result-img {
  width: 24px;
  height: 25px;
  background-size: cover;
  background-position: top center;
}

.search-result-title {
  margin-left: 15px;
}

.mobile-search-box .search-results-box {
  top: 100%;
  margin-top: 0;
}

.follow-popup {
  width: 600px;
  height: 600px;
  background-color: #ffffff;
  overflow: scroll;
  max-width: 100%;
  max-height: 100%;
}

.mailchimp-popup {
  width: 320px;
  height: 270px;
  background-color: #ffffff;
  overflow: scroll;
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  border: none;
}

.follow-header {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.follow-item {
  display: flex;
  padding: 0 24px;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  border-top: 1px solid #f0f0f0;
}

.follow-lunchbox-item {
  padding: 0 24px;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  margin-top: 15px;
}

.follow-lunchbox-item a {
  font-weight: 600;
  color: #934fa0;
}

.follow-pic {
  width: 36px;
  height: 36px;
  background-size: cover;
  background-position: top center;
  background-image: url("/images/user.png");
}

.follow-left {
  display: flex;
  align-items: center;
}

.follow-name {
  margin-left: 18px;
  font-weight: 500;
}

.follow-small-button {
  background-color: #934fa0;
  font-size: 14px;
  color: white;
  font-weight: 600;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.menu-print-page {
  margin-top: 60px;
}

.print-logo {
  height: 25px;
  position: absolute;
  text-align: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  display: none;
}

@media print {
  .print-logo {
    display: block;
  }
}

.menu-print-page h1 {
  text-align: center;
  margin-top: 10px;
}

.menu-boxes {
  margin-top: 30px;
  display: flex;
  max-width: 100%;
  justify-content: space-around;
}

.lunch-print-box,
.lunch-print-place-holder {
  flex-basis: 18%;
  border: 2px solid #000000;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lunch-print-box img {
  width: 80%;
  height: 150px;
  margin-bottom: 24px;
}

.food-item-print {
  margin-bottom: 16px;
}

.menu-boxes h3 {
  margin-bottom: 16px;
}

.menu-print-back {
  position: relative;
  left: 20px;
  cursor: pointer;
}

@media print {
  .menu-print-back {
    display: none;
  }
}

.shoppinglist-print-page {
  margin-top: 60px;
}

.shoppinglist-print-page h1 {
  text-align: center;
  margin-top: 10px;
  justify-content: center;
}

.shoppinglist-boxes {
  margin-top: 30px;
  margin-left: 5px;
}

.shoppinglist-entry {
  margin: 5px;
}

.shoppinglist-boxes h3 {
  margin-top: 30px;
  margin-bottom: 10px;
}

.shoppinglist-print-back {
  left: 20px;
  cursor: pointer;
}

@media print {
  .shoppinglist-print-back {
    display: none;
  }
}

.challenge-bold {
  font-weight: bold; 
}