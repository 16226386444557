.app-container {
  background-color: #ffffff;
}

.splash-header {
  height: 420px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("/images/banner.jpg");
  position: relative;
}

.splash-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(59, 59, 59, 0.5);
}

.splash-header h1 {
  color: white;
  margin-bottom: 12px;
}

.splash-header h4 {
  margin-bottom: 30px;
  color: white;
}

.splash-header h1,
.splash-header h4,
.splash-header a {
  z-index: 1;
}

.contest-announcement {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #934ea0;
  position: relative;
}

.contest-announcement-homepage {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #934ea0;
  position: relative;
}

.contest-announcement-homepage-with-image {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 15px;
  background-color: #934ea0;
}

.contest-announcement-homepage-image {
  height: 100px;
  width: 100px;
  background-color: #934ea0;
  justify-content: flex-end;
  align-items: center;
}

.contest-title {
  padding-bottom: 10px;
  padding-left:10px;
  padding-right:10px;
  text-align: center;
}

.contest-title-link {
  font-weight: bold;
  color: #ffffff;
  line-height: 160%;
}

.full-menu-option-cta-button-message {
  border-width: 1px;
  border-style: solid;
  border-radius: 22px;
  border-color: #FFFFFF;
  color: #FFFFFF;
  font-weight: bold;
  height: 30px;
  margin-top: 10px;
  margin-left: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 5px;
  margin-right: 10px;
}

.contest-text {
  font-size: 14px;
  line-height: 160%;
}

.contest-rules {
  font-size: 14px;
  color: #2a3daf;
}

.link_to_feed_from_description {
  font-weight: bold;
  color: #934ea0;
  text-decoration-line: underline;
}

.video_demo_teuko_feed_on_landing_page {
  width: 500px;
  height: 250px;
  object-fit: contain;
  overflow-clip-margin: content-box;
  overflow: clip;
  -webkit-tap-highlight-color: transparent;
}

.testbanner-ad1234 {
  width: 300px; height: 250px;
}

.landing-heading-section {
  padding: 48px 0 1px;
  background-color: #f6eff7;
}

.landing-features-section {
  background-color: #ffffff;
}

.landing-highlight-word-in-title {
  font-weight: bold;
  color: #934ea0;
}

.landing-text-xs {
  font-size: 0.75rem;
  color: #6b7280;
  text-align: center;
  width: 280px;
}

.landing-heading-section-subtitle {
  margin: auto;
}

.simple-fun-section {
  padding: 1px 0 0;
  background-color: #f6eff7;
}

.simple-fun-section-landing {
  padding: 10px 0 0;
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 30px;
}

.giveaway-banner-btn-landingpage {
  margin-top: 45px;
}

.giveaway-section {
  margin-top: 20px;
  max-width: 100%;
  text-align: center;
  line-height: 1.2;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.giveaway-section h2 {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #000000;
}

.giveaway-textothercolor {
  color: #CC0000;
}

.giveaway-section_intro {
  width: 850px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}

.giveaway-section_intro p {
  margin-top: 10px;
  margin-bottom: 25px;
}
.giveaway-section_intro a {
  font-size: 16px;
  font-weight: 400;
  color: #934fa0;
}

.giveaway-section_submit {
  margin-top: 25px;
  margin-bottom: 25px;
}

.giveaway-section_submit p {
  font-weight: bold;
  margin-top: 2px;
  margin-bottom: 20px;
}

.giveaway-section_submit a {
  font-size: 16px;
  font-weight: 400;
  color: #934fa0;
}

.giveaway-section_submit input {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.giveaway-submit_email {
  width: 300px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.giveaway-submit_btn {
  background-color: #934fa0;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 5px;
  -webkit-appearance: none;
}

.giveaway-validation-message p {
  color: rgb(41, 129, 41);
  font-size: 14px;
  font-weight: normal;
}

.giveaway-terms-privacy p {
  font-size: 10px;
  font-weight: 400;
  color: #A6A6A6;
  margin-top: 15px;
}
.giveaway-terms-privacy a {
  font-size: 10px;
  color: #004b79;
}

.solid {
  border: 1px solid #d9d9d9;
  margin-top: 30px;
  margin-bottom: 30px;
}

.solid-giveaway {
  border: 1px solid #389477;
  margin-top: 30px;
  margin-bottom: 30px;
}

.landing-heading-section h1,
.landing-heading-section h2,
.landing-heading-section h3,
.landing-heading-section p {
  width: 850px;
  max-width: 100%;
  text-align: center;
  line-height: 1.2;
}

.landing-features-section h1,
.landing-features-section h2,
.landing-features-section h3,
.landing-features-section p {
  width: 850px;
  max-width: 100%;
  text-align: center;
  line-height: 1.2;
}

.landing-heading-section-subtitle {
  width: 650px;
  max-width: 100%;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 20px;
}

.landing-features-section-subtitle {
  width: 650px;
  max-width: 100%;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 20px;
}

.landing-heading-section-containerflex {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.navigation-features {
  margin-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
  width: 100%;
  z-index: 0;
}

.navigation-features-list {
  padding-left: 0;
  background-color: transparent;
  justify-content: flex-start;
  border-radius: calc((16px + 2.24rem)/2);
  margin: 0;
  padding: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  text-align: -webkit-match-parent;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
}

.navigation-features-list-item {
  color: rgba(17,23,29,.6);
  align-items: center;
  display: flex;
  flex-direction: row;
}

.navigation-features-list-item-link {
  color: #0e1318;
  display: inline-block;
  text-decoration: none;
  transition: color .3s;
}

.navigation-features-list-item-nolink {
  color: grey;
  display: inline-block;
  text-decoration: none;
  transition: color .3s;
}

.navigation-features-list-item-signet {
  color: #0e1318;
  width: 100%;
  height: 100%;
  display: block;
  margin-left: 8px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: text-bottom;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6;
}

.features-heading-section-containerflex {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 40px;;
}

.landing-features-section-containerflex {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.landing-heading-section-textcta {
  order: 1;
  align-self: center;
  width: 650px;
}
.landing-heading-section-image {
  order: 2;
  align-self: center;
}

.landing-features-lunch-image {
  border-style: solid;
  border-width: 2px;
  border-color: #f6eff7;
  border-radius: 4px;
  margin-right: 20px;
}

.landing-features-lunch-image2 {
  width: 500px;
  height: 250px;
}

.landing-features-section-image {
  order: 1;
  align-self: auto;
  display: block;
}
.landing-features-section-textcta {
  order: 2;
  align-self: auto;
}

.landing-heading-section h1 {
  margin: 0px auto 40px;
  background-color: #f6eff7;
}

.landing-heading-section h2 {
  margin: 0 auto;
}
.landing-features-section h2 {
  margin: 0 auto;
}

.landing-heading-section h3 {
  margin: 0 auto;
}
.landing-features-section h3 {
  margin: 0 auto;
}

.landing-heading-lunch-image {
  padding-right: 100px;
}

.a0G {
  padding-top: 16px;
  padding-bottom: 16px;
}

.BcmU7 {
  margin-bottom: -8px;
  margin-left: -8px;
  display: flex;
  flex-wrap: wrap;
}

.homepage-supporteradbox {
  justify-content: flex-end;
  padding-bottom: 8px;
  padding-left: 8px;
  margin-right: 15px;
  display: flex;
  flex-basis: 33.33333%;
  flex-grow: 1;
  font-size: 13px;
}

.homepage-supporteradtext {
  position: relative;
  display: flex;
  flex-direction: column;
}

.homepage-supporteradtextspec {
  color: #737373;
  font-weight: 400;
}

.homepage-supporteradimage {
  position: absolute;
  bottom: 24px;
  display: flex;
  align-items: flex-end;
}

.homepage-supporteradimagespec {
  max-width: 150px;
  max-height: 56px;
  object-fit: contain;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
  font-family: "object-fit:contain";
  vertical-align: middle;
  border-radius: 8px;
}

.simple-fun-section h1,
.simple-fun-section h2,
.simple-fun-section h3 {
  width: 700px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  line-height: 1.2;
}

.simple-fun-section-landing h1,
.simple-fun-section-landing h2,
.simple-fun-section-landing h3 {
  width: 700px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  line-height: 1.2;
}

.simple-fun-section h1,
.landing-heading-section h1 {
  margin-top: 60px;
  margin-bottom: 15px;
  font-size: 48px;
}
.simple-fun-section h2 {
  margin-top: 50px;
}

.simple-fun-section-landing h1 {
  margin-top: 60px;
  margin-bottom: 15px;
  font-size: 48px;
}
.simple-fun-section-landing h2 {
  margin-top: 50px;
}

.lunch-and-bullets {
  display: flex;
  position: relative;
  min-height: 380px;
  justify-content: flex-end;
}

.lunch-image {
  width: 500px;
  position: absolute;
  left: 0;
  top: 80px;
}

.lunch-video {
  margin-top: 15px;
  margin-bottom: 30px;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
}

.feature-item img {
  width: 64px;
  height: 64px;
  flex: 1;
  min-width: 64px;
  border-radius: 50%;
}

.feature-text {
  margin-left: 36px;
}

.feature-text p {
  line-height: 1.3;
  font-weight: 500;
}

.feature-title {
  font-weight: 600 !important;
  margin-bottom: 12px;
}

.features-list {
  padding: 72px 0px 0px;
  width: 600px;
  height: 300px;
  align-items: flex-end;
  padding-right: 64px;
  z-index: 2;
}

.features-partners-list {
  padding: 72px 0px 0px;
  width: 600px;
  height: 100%;
  align-items: flex-end;
  padding-right: 64px;
  z-index: 2;
}

.testimonial-partners-school {
  background-color: #ffffff;
  padding-top: 6rem;
  padding-bottom: 5.875rem;
  padding-right: 6rem;
  padding-left: 6rem;
}

.testimonial-partners-school-inner {
  max-width: 635px;
  margin: 0px auto;
  color: rgb(87, 84, 82);
  font-size: 1.375rem;
  line-height: 2.25rem;
}

.testimonial-partners-school-name {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5rem;
}

.title-comparison {
  width: 100%;
}

.title-comparison h2 {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
}

.comparison-module-school {
  font-size: 1.8rem;
  line-height: 1rem;
  font-weight: 400;
  margin: 0;
  padding: 1.2rem 0;
  background-color: #ffffff;
}

.onboarding-plans {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.infocard {
  width: 17rem;
  min-width: 17rem;
  padding: 1.2rem 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  background-color: #f6eff7;
}

.infocard-landing {
  width: 250px;
  margin-bottom: 2rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.infocard-boutique {
  width: 200px;
  height: 120px;
  margin-bottom: 2rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.infocard-blog {
  width: 200px;
  height: 120px;
  margin-bottom: 2rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.infocard-header {
  text-align: center;
}

.infocard-header-landing {
  text-align: center;
}

.infocard-header-landing h3 {
  color: #4f4f4f;
  padding-top: 10px;
  padding-bottom: 10px;
}

.infocard-header h3 {
  color: #934fa0;
  margin-top: 10px;
  text-align: center;
}

.infocard-cta {
  margin: 1.4rem 2rem;
  text-align: center;
}

.upgradecard-listitem {
  text-align: left;
  padding-bottom: 7px;
}

.latest-lunches-plus-reviews {
  padding: 50px 0 0 0;
  background-color: #ffffff;
}

.subscribe {
  padding: 35px 0 25px 0;
  background-color: #f6eff7;
}

.landing-social-shares {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  color: #ffffff;
}

.profile-trophy-case {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #d9d9d9;
  padding: 5px 15px;
  border-radius: 25px;
  margin-left: 15px;

}

.trophy-case-profile-separator-div {
  height: 5px;
  width: 90%;

}

.trophy-case-profile-separator {
  border-top: 1px solid #d9d9d9;
  margin-top: 2px;
  margin-bottom: 2px;
}

.trophies-social-shares {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 15px; */
  /* margin-left: 20px; */
  color: #737373;
}

.landing-social-shares ul{
  list-style:none;
  margin: 0;
  padding: 0;
  margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.trophies-social-shares ul{
  list-style:none;
  margin: 0;
  padding: 0;
  margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.landing-social-shares li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.trophies-social-shares li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.social-shares_list-item a{
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
}

.social-shares__icon-wrapper{
  display: flex;
    align-items: center;
    padding: 0.5em 0.75em;
    box-shadow: 0 0 0 1px #ffffff;
    border-radius: 0.25em;
    margin: 10px 0.375em;
    color: #ffffff;
    fill: #ffffff;
}

.trophies-social-shares__icon-wrapper{
  display: flex;
    align-items: center;
    padding: 0.5em 0.5em;
    box-shadow: 0 0 0 1px #d9d9d9;
    border-radius: 0.25em;
    margin: 5px 0.375em;
    color: #737373;
    fill: #737373;
}

.trophies-social-shares__icon-wrapper:hover {
  color: #4f4f4f;
}

.social-shares__icon {
  width: 1.5em;
  height: 1.5625em;
  fill: inherit;
}

.icon {
  display: inline-block;
    width: 0.8em;
    height: 1em;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.visuallyhidden {
  border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

.kids-social-share__button-text {
  color: inherit;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.7px;
    padding-left: 0.5em;
}

.trophies-social-share__button-text {
  color: inherit;
    font-weight: 500;
    padding-left: 0.5em;
}

.plus-reviews {
  padding-top: 50px;
  background-color: #ffffff;
}

.latest-lunches h2 {
  margin-bottom: 20px;
}

.reviews h2 {
  text-align: center;
}

.subscribe h2 {
  text-align: center;
  margin-bottom: 15px;
}

.subscrible h5 {
  text-align: center;
}

.lunch-group {
  margin-top: 48px;
  display: grid;
  grid-template-columns: 250px 250px 250px 250px;
  justify-content: center;
  grid-gap: 50px;
}

.lunch-image-small {
  width: 250px;
  height: 250px;
  border-radius: 23px;
  background-size: cover;
  background-position: top center;
}

.lunch-o-details {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.owner-mini-image {
  width: 44px;
  height: 44px;
  background-size: cover;
  margin-right: 16px;
}

.owner-name {
  font-weight: 500;
  font-size: 14px;
}

.container-browse-all-btn {
  margin: auto;
  width: 80%;
}

.container-browse-btn_giveaway-landing {
  margin-top: 45px;
}

.browse-all-button {
  display: inline-block;
  margin: 0px auto 10px auto;
  border-radius: 4px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.challenges-button {
    background-color: #934ea0;
    padding: 10px 40px;
    color: white;
    font-weight: 500;
    font-family: "GT America Standard", sans-serif;
    letter-spacing: 1px;
    border-radius: 4px;
    max-width: 100%;
}

.separator-home {
  background-color: #f5f5f5;
  width: 75%;
  height: 2px;
  margin: 0 auto;
  max-width: 1000px;
}

.reviews {
  background-color: #ffffff;
  margin-bottom: 40px;
}

.home-page {
  background-color: #ffffff;
}

.reviews h3 {
  text-align: center;
}

.reviews h5 {
  text-align: center;
  margin-bottom: 15px;
}

.review-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.review-image {
  margin-right: 50px;
}

.review-content {
  width: 540px;
  line-height: 1.4;
}

.reviews .slider {
  margin-top: 48px;
}

.reviews .slider-list {
  max-height: 270px;
}

.latest-lunches {
  text-align: center;
}

.review-content p {
  font-weight: 500;
}

.review-content strong {
  font-weight: 600;
}

.review-content p:last-of-type {
  margin-top: 24px;
}

.reviews-button {
  display: inline-block;
  margin: 36px auto;
  border-radius: 4px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.div-joinfree-button {
  text-align: center;
  padding-top: 15px;
}

.joinfree-button {
  display: inline-block;
  margin: 36px auto;
  border-radius: 4px;
  transform: translateX(-50%);
  display: none;
}

.joinfreenewsletter-button {
  display: inline-block;
  margin: 36px auto;
  border-radius: 4px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.joinuslaptop-button {
  display: inline-block;
  margin: 50px auto;
  border-radius: 4px;
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: #f3cd00;
}

.joinuslaptop-button:hover {
  background-color: #ab9000;
}

.joinusipadcellphone-button {
  visibility: hidden;
}

.slider-decorator-0,
.slider-decorator-1 {
  display: none;
}

.slider-decorator-2 button {
  color: #f3cd00 !important;
  font-size: 42px !important;
  padding: 4px !important;
  top: 95px;
  position: relative;
  margin-bottom: 50px;
}

.featured-in {
  background-color: #ffffff;
  height: 140px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.featured-in h2 {
  text-align: center;
  margin-top: 15px;
}

.feature-options {
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  max-width: 1100px;
}

.partners-logos-options {
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  max-width: 1100px;
}

.feature-options img {
  height: 60px;
}

.partners-logos-options img {
  width: 150px;
  height: 50px;
  padding-right: 5px;
  padding-left: 5px;
}

.feature-options span {
  font-weight: 600;
}

.partners-logos-options span {
  font-weight: 600;
}

.bottom-nav {
  overflow: hidden;
  background-color: rgb(220, 198, 224);
  position: fixed;
  bottom: 0;
  width: 100%;
  align-items: center;
}

.footer2 {
  border-top: 1px solid rgba(43,59,74,.3);
  box-sizing: border-box;
  width: 100%;
}

.footer2-interior-div {
  max-width: 2560px;
  margin: 0 auto;
  padding: 0 32px;
}

.footer2-nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
}

.footer2-resources_links {
  clip: rect(1px,1px,1px,1px);
  border: 0;
  height: 1px;
  left: 0;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1px;
}

.footer2-column_resources {
  border-top: none;
  flex-basis: 0;
  flex-grow: 1;
  margin: 0;
  display: block;
  font-size: 1.2rem;
  line-height: 1.9rem;
  -webkit-font-smoothing: antialiased;
}

.footer2_column_title {
  display: flex;
  justify-content: space-evenly;
  padding: 48px 0 0;
}

.footer2_column_title_h3 {
  color: #000000;
  text-align: start;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.footer2_column_list_resources_ul {
  justify-content: center;
    font-size: 1.1rem;
    line-height: 1.6rem;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
    font-weight: 400;
    overflow-wrap: break-word;
    display: block;

}

.footer2_column_list_resources_li {
  display: list-item;
  text-align: center;
  justify-content: center;
  
}

.footer2_column_list_resources_p {
  color: #0d1216;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.6rem;
  transition: color .2s;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: color;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.footer2_column_list_resources_p:hover {
  color: #934fa0 !important;
}

.footer2_column_list_resources_a {
  transition: color .2s;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: color;
  text-decoration: none;
  color: inherit;
  -webkit-user-drag: none;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.6rem;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  overflow-wrap: break-word;
}

.footer2-section_privacy_and_social {
  flex-direction: row;
  border-top: 1px solid rgba(43,59,74,.3);
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  font-size: 1.1rem;
  line-height: 1.6rem;
  overflow-wrap: break-word;
}

.footer2-section_privacy_and_social-terms_privacy {
  flex-grow: 3;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.1rem;
  line-height: 1.6rem;
  overflow-wrap: break-word;
}

.footer2-section_privacy_and_social-terms_privacy_all_rights_reserved_p {
  margin-bottom: 8px;
  color: rgba(13,19,22,.7);
  text-align: center;
  display: block;
  font-size: 1.1rem;
  line-height: 1.6rem;
  overflow-wrap: break-word;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.footer2-section_privacy_and_social-terms_privacy_all_rights_reserved_span {
  color: rgba(13,18,22,.7);
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.6rem;
  overflow-wrap: break-word;
}

.footer2-section_privacy_and_social-terms_privacy_privacy_terms_span {
  grid-gap: 8px;
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.6rem;
  overflow-wrap: break-word;
}

.footer2_section_privacy-privacy_a {
  color: #293039;
  text-decoration: none;
  transition: color .2s;
  font-size: 1.1rem;
  line-height: 1.6rem;
  overflow-wrap: break-word;
}

.footer2-section_privacy_and_social-terms_privacy_separator_span {
  background-color: currentColor;
  color: #293039;
  height: 16px;
  width: 1px;
  text-align: center;
}

.footer2-section_social {
  display: flex;
  justify-content: flex-end;
}

.footer2-section_social-logo_a {
  margin-right: 8px;

}

.footer2-section_social-logo_span_facebook {
  color: #1877f2;
  height: 24px;
  width: 24px;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
}

.footer2-section_social-logo_span_twitter {
  color: #1da1f2;
  height: 24px;
  width: 24px;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
}

.footer2-section_social-logo_span_pinterest {
  color: #bd081c;
  height: 24px;
  width: 24px;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
}

.footer2-section_social-logo_span_youtube {
  color: #bd081c;
  height: 24px;
  width: 24px;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  margin-top: 5px;
}

.footer2-section_social-logo_span_instagram {
  eight: 24px;
  width: 24px;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
}

.footer2-Facebook_logo {
  clip: rect(1px,1px,1px,1px);
    border: 0;
    height: 1px;
    left: 0;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 1px;
}

.footer {
  background-color: #272727;
  display: flex;
  padding: 36px 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.footer-icons {
  display: flex;
  height: 45px;
  justify-content: center;
  margin: auto;
}

.footer-icons svg {
  color: #333;
  font-size: 20px;
  margin: 3px 25px 2px 25px;
}

.footer-icons-text {
  font-size: 10px;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.footer-icons-social {
  display: flex;
  height: 45px;
}

.footer-icons-social svg {
  color: rgb(250, 250, 250);
  font-size: 23px;
  margin: 0 11px;
}

.footer-links {
  margin: 16px auto;
  display: flex;
}

.footer-links a {
  color: white;
  font-size: 12px;
  font-weight: 600;
  margin: 0 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.copyright {
  font-size: 12px;
  color: white;
}

.popup-modal {
  position: fixed;
  top: 50%;
  z-index: 999;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 650px;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
  max-width: 100%;
  overflow: scroll;
  max-height: 100%;
  background-size: 190% 150%;
  background-position: 60% 90%;
}

.accounts-modal,
.reset-page {
  position: relative;
  top: 0;
  transform: none;
  left: 0;
  margin: 40px auto;
  z-index: 0;
}

.reset-page,
.accounts-modal {
  margin-bottom: 35vh;
}

.close-icon {
  position: absolute;
  color: #b9b8b8;
  top: 40px;
  right: 30px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.popup-modal h3 {
  margin-bottom: 32px;
}

.connect-header {
  color: #a4a4a4;
  line-height: 1.2;
}

.sign-up-options {
  display: block;
  margin-top: 20px;
  position: relative;
}

.sign-up-options a {
  display: inline-block;
}

.sign-up-options h6 {
  margin: 16px 0;
}

.accounts-link {
  margin-top: 24px;
  color: #924e9f;
}

.facebook-button {
  border-radius: 27.5px;
  text-transform: uppercase;
  font-size: 15px;
  background-color: #3b5997;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.facebook-button:hover {
  background-color: #3b5997;
}

.facebook-button i {
  font-size: 22px;
  color: white;
  margin-right: 10px;
}

.facebook-login-button {
  border-radius: 4px;
  margin-top: 12px;
}

.full-input {
  display: block;
  width: 400px;
  margin: 0 auto 12px;
  box-sizing: border-box;
  font-size: 18px;
  color: #343434;
}

.full-input-mailchimp {
  display: block;
  align-items: center;
  text-align: center;
  width: 280px;
  margin: 0 auto 12px;
  box-sizing: border-box;
  font-size: 18px;
  color: #343434;
}

.account-details-group {
  margin-bottom: 12px;
  display: block;
  position: relative;
}

.account-button {
  width: 400px;
  box-sizing: border-box;
}

.teuko-agreement {
  width: 400px;
  margin: 15px auto 0;
  max-width: 100%;
}

.teuko-agreement p,
.teuko-agreement a {
  font-size: 14px;
  font-weight: 500;
  color: #aaa4a4;
  line-height: 1.5;
  max-width: 100%;
}

.teuko-agreement a {
  color: #924e9f;
  outline: none;
  max-width: 100%;
}

.loginpage-invitesignup {
  width: 400px;
  margin: 15px auto 0;
  max-width: 100%;
}

.loginpage-invitesignup p {
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}

.loginpage-invitesignup a {
  color: #924e9f;
  outline: none;
  max-width: 100%;
}

.secondary-sign-up {
  margin-top: 60px;
}

.email-address {
  margin-bottom: 36px;
}

.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #000000 transparent transparent;
  border-style: solid;
  border-width: 10px 10px 0;
  content: " ";
  display: block;
  height: 0;
  position: absolute;
  right: 20px;
  top: 50%;
  width: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #000000;
  border-width: 0 10px 10px;
}

.Dropdown-menu {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1.2);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: flex;
  padding: 0px 20px;
  height: 49px;
  align-items: center;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.location-input-group {
  display: flex;
  max-width: 400px;
  position: relative;
}

.input-group {
  width: 400px;
  margin: 0 auto 24px;
  position: relative;
  max-width: 100%;
}

.country-dropdown {
  width: 225px;
  height: 49px;
  flex: 1;
}

.zip-input {
  margin-left: 10px;
  font-size: 18px;
  box-sizing: border-box;
  width: 120px;
}

.Dropdown-control {
  height: 57px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
}

.sign-up-button {
  display: block;
  width: 400px;
  padding: 18px 0;
  margin: 0 auto;
  max-width: 100%;
  box-sizing: border-box;
}

.mailchimp-subscribe-button {
  display: block;
  width: 250px;
  padding: 18px 0;
  margin: 0 auto;
  max-width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.go-back {
  margin-top: 24px;
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: #aaa4a4;
  cursor: pointer;
}

.discover-small-splash-header {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
  text-align: center;
  background-size: cover;
  height: 300px;
  width: 300px;
  background-position: top center;
  position: relative;
}

.challenges-featured-banner {
  background-color: grey;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  border: 1px solid #d9d9d9;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.challengesrules-featured-banner {
  background-color: grey;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
}

.challenges-featured-details {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 80%;
  border: 1px solid #d9d9d9;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.challengesrules-featured-details {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.challengesrules-featured-details h2 {
margin-top: 25px;
margin-bottom: 5px;
}

.challengesrules-featured-trophy-badge-image {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: 10px;
}

.challengesrules-featured-trophy-badge {
  margin: auto;
}

.challenges-featured-trophy-badge-image {
  width: 150px;
  height: 150px;
  margin-top: 10px;
  margin-left: 10px;
}

.challenges-featured-text-details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  margin: auto;
}

.challenges-featured-text-title {
  margin: auto;
  margin-bottom: 10px;
}

.challenges-featured-rule-short {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.challenges-visual-rules {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.challenges-featured-prize-short {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.challenges-visual-prizes {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.challenges-featured-dates {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.challenges-visual-dates {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.challenges-button-cta-complete-rules {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 5px;
}

.challenge-group-items-details {
  margin-bottom: 10px;
}

.feed-ad-small-splash-header {
  background-color: black;
  color: white;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 25px;
  text-align: center;
  background-size: cover;
  height: 330px;
  background-position: top center;
  position: relative;
}

.pub-amazn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

.pub-amazn-smartphone {
  display: none;
}
.pub-banner-smartphone {
  display: none;
}

.search-google-ad-banner-type-leaderboard-centered {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.search-title-header-ad-smartphone_leaderboard {
  display: none;
}
.search-title-header-ad-smartphone_leaderboard2 {
  display: none;
}

.lunchbox-cards-header-ad-smartphone_leaderboard {
  display: initial;
  width: 320px;
  height: 50px;
  /* background-color: grey; */
  margin-top: 5px;
}

.mylunches-title-header-ad-leaderboard {
  display: initial;
  width: 320px;
  height: 50px;
  /* background-color: grey; */
  margin-top: 10px;
  margin-bottom: 5px;
}

.search-title-header-ad-laptop_leaderboard {
  /* background-color: grey; */
  width: 728px;
  height: 90px;
  margin-top: 15px;
  margin-bottom: 5px;
}

.pub-banner-laptop-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pub-banner-laptop {
  /* background-color: grey; */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 25px;
  text-align: center;
  background-size: cover;
  height: 90px;
  width: 728px;
  background-position: top center;
}

.pub-banner-advertisement-label {
  margin-top: 35px;
  margin-bottom: 5px;
  color: #a4a4a4;
  font-size: 8px;
  text-align: right;
}

.pub-banner-advertisement-middle-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: grey; */
}

.small-splash-header {
  height: 260px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 85px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
}

.signup-small-splash-header {
  height: 250px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
}

.giveaway-splash-header-laptop {
  height: 350px;
  width: 837px;
  top: 55px;
  background-color: #389477;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
}
.giveaway-splash-header-laptop-bis {
  height: 284px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
}

.giveaway_gifts-splash-header-laptop {
  height: 475px;
  top: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
  text-align: center;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
}

.giveaway_partners-splash-header-laptop {
  height: 150px;
  top: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
}

.giveaway-splash-header-smartphone {
  display: none;
}
.giveaway_gifts-splash-header-smartphone {
  display: none;
}
.giveaway_partners-splash-header-smartphone {
  display: none;
}

.partners-small-splash-header {
  height: 260px;
  background-color: #f6eff7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
}

.features-small-splash-header {
  height: 200px;
  background-color: #f6eff7;
  background-image: url("/images/footer features teuko lunchbox community kids food meal prep bento school lunch.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
}

.contact-small-splash-header {
  height: 260px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
}

.overlay-small-header {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.61);
}

.xoegz02 {
  display: block;
  z-index: 9999;
}

.ab8q {
  width: 100%;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, .8)!important;
  padding-bottom: calc(var(--base-unit) * 5);
  padding-top: calc(var(--base-unit) * 5);
  padding-left: calc(var(--base-unit) * 4);
  padding-right: calc(var(--base-unit) * 4);
  right: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  box-sizing: border-box;
  z-index: 9999;
  
}

.x78zum5 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}



.x9f619 {
  /* width: 903px; */
  overflow-y: visible;
  border-bottom-left-radius: 0;
  justify-content: center;
  background-color: transparent;
  align-self: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-right-radius: 0;
  flex-shrink: 0;
  overflow-x: visible;
  position: relative;
  flex-grow: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.acps {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-right: 100px;
  margin-top: 0;
  min-width: 125px;
  /* flex: 10000 1 0px; */
}

.x9f619 {
  overflow-y: visible;
  margin-bottom: 12px;
  border-bottom-left-radius: 0;
  background-color: transparent;
  box-sizing: border-box;
  border-bottom-right-radius: 0;
  flex-shrink: 0;
  overflow-x: visible;
  align-self: auto;
  position: relative;
  display: block;
  flex-grow: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.xjbqb8wlogo {
  height: 120px;
  width: 120px;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 50%;
  overflow-y: visible;
  background-color: transparent;
  /* flex-direction: row; */
  box-sizing: border-box;
  /* display: flex; */
  flex-shrink: 0;
  align-items: stretch;
  overflow-x: visible;
  align-self: auto;
  /* justify-content: flex-start; */
  position: relative;
  flex-grow: 0;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
}

.xjbqb8wlogoimage {
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
}

.aacl {
  text-align: left;
  display: block;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin: -3px 0 -4px;
}

.aadf {
    word-wrap: break-word;
    white-space: normal;
    text-align: left;
    display: block;
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin: -3px 0 -4px;
}

.jobs-header {
  background-position: center;
}

.small-splash-header h1 {
  color: white;
  z-index: 3;
}

.partners-small-splash-header h1 {
  color: black;
  z-index: 3;
}

.partners-small-splash-header h2 {
  margin-top: 15px;
}

.partners-small-splash-header p {
  margin-top: 15px;
}

.contact-small-splash-header h1 {
  color: white;
  z-index: 3;
}

.story {
  display: flex;
  justify-content: center;
}

.story-photo {
  width: 480px;
  height: 830px;
  background-color: blue;
  flex: 1 0 auto;
  margin-right: 72px;
  box-sizing: border-box;
}

.story-content {
  width: 800px;
  text-align: center;
  max-width: 90%;
}

.story-content h4 {
  margin-bottom: 27px;
}

.story-content p {
  line-height: 22px;
  padding-bottom: 24px;
}

.our-team-section {
  margin-top: 72px;
}

.our-team-section h4 {
  text-align: center;
}

.team-members {
  margin-top: 35px;
  display: flex;
  justify-content: space-around;
}

.team-member-photo {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 32px;
  background-size: cover;
  background-position: top center;
}

.team-member {
  width: 350px;
  text-align: center;
  max-width: 100%;
}

.discover {
  justify-content: center;
  display: flex;
}

.discover-page {
  margin-bottom: 20px;
}

.discover-section {
  /* width: 300px;
  margin-top: 35px;
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.discover-posts-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 30px 30px;
}

.discover-posts {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.discover-photo {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 32px;
  background-size: cover;
  background-position: top center;
}

.discover-content {
  width: 300px;
  text-align: center;
  max-width: 90%;
}

.discover-content h4 {
  margin-bottom: 27px;
}

.discover-content p {
  line-height: 22px;
  padding-bottom: 24px;
}

.about-us-page,
.discover-page,
.jobs-page,
.contact-page {
  padding-bottom: 100px;
}

.team-member strong {
  font-weight: 500;
}

.team-member h5,
.team-member p {
  margin-bottom: 18px;
  line-height: 22px;
}

.team-member p,
.team-member a {
  font-size: 16px;
}

.team-member a {
  color: #934fa0;
  cursor: pointer;
  font-weight: 500;
}

.small-splash-header p {
  color: white;
  margin-top: 35px;
  z-index: 3;
}

.job-list {
  width: 950px;
  max-width: 100%;
  margin: 0 auto;
}

.job-box {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 4px;
}

.job-header {
  background-color: #934fa0;
  color: white;
  padding: 14px;
  font-weight: 500;
}

.job-content {
  padding: 14px;
}

.job-subtitle {
  font-size: 16px;
  color: #b9b9b9;
  margin-top: 6px;
}

.job-title {
  font-size: 16px;
  font-weight: 500;
}

.job-disclaimer {
  width: 720px;
  margin: 48px auto 0;
  max-width: 100%;
}

.job-disclaimer p {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #b1b1b1;
  margin-bottom: 14px;
}

.contact-section .standard-container {
  display: flex;
}

.contact-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-right {
  flex: 1;
  text-align: center;
  width: 450px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cform-group {
  padding-bottom: 20px;
}

.cform-group label {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin-bottom: 15px;
}

.cf-input {
  background-color: #f9f9f9;
  width: 450px;
  max-width: 100%;
  border: 1px solid #ededed;
  box-sizing: border-box;
  font-size: 18px;
}

.contact-section {
  background-color: #ffffff;
}

.contact-page {
  background-color: #ffffff;
}

textarea.cf-input {
  resize: none;
  padding: 18px 20px;
  height: 175px;
}

.cf-submit {
  width: 450px;
  max-width: 100%;
  background-color: #a2d15f;
  color: white;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.contact-right h4 {
  text-align: center;
  margin-bottom: 15px;
  width: 450px;
  max-width: 100%;
}

.contact-right p,
.contact-right a {
  font-weight: 400;
  line-height: 28px;
  width: 450px;
  max-width: 100%;
}

.contact-right a {
  color: #3b5997;
}

.c-separator {
  height: 1px;
  background-color: black;
  width: 450px;
  max-width: 100%;
  margin: 20px 0;
}

.faq-page {
  background-color: white;
  padding-bottom: 85px;
}

.giveaway-page {
  background-color: white;
  padding-bottom: 2px;
  width: 100%;
}

.faq-group {
  padding-bottom: 64px;
}

.faq-group h4 {
  text-align: center;
  padding-bottom: 42px;
}

.question-faq {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.open-question-icon {
  background-color: #a2d15f;
  padding: 10px;
  border-radius: 50%;
  fill: white;
  cursor: pointer;
}

.q-and-a-faq {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 28px;
  margin-bottom: 36px;
}

.question-answer {
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
  transition: max-height 300ms ease, padding-top 300ms ease;
  white-space: pre-line;
}

.question-answer-open {
  padding-top: 24px;
  max-height: 300px;
  padding-bottom: 8px;
}

.boutique-page {
  padding: 45px 0;
  min-height: 90vh;
}

.boutique-page h1 {
  margin-left: 56px;
}

.boutique-affiliate-message p {
  font-size: 10px;
  font-style: italic;
  font-color: #d1d1d1;
  margin-top: 3px;
  margin-bottom: 8px;
  text-align: center;
}

.discover-page h1 {
  margin-left: 56px;
}

.boutique-page .standard-container {
  display: flex;
}

.boutique-menu {
  width: 300px;
  position: fixed;
}

.boutique-groups {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.giveaway-groups {
  flex: 6;
  align-items: center;
  justify-content: center;
}

.boutique-menu-links {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.boutique-menu-link {
  margin-bottom: 18px;
  font-weight: 500;
  cursor: pointer;
}

.boutique-group-items {
  margin: 18px 0 54px;
  display: grid;
  grid-template-columns: repeat(5, 200px);
  grid-gap: 40px;
  align-items: center;
  justify-content: center;
}

.trophies-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:space-around;
  align-items: baseline;
  gap: 10px 30px;
  /* margin-bottom: 15px; */
}

.challenges-ad-banner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
  align-items: center;
  margin-bottom: 15px;
}

.blog-ad-banner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.lunchbox-cards-ad-banner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
  align-items: center;
  margin-top: 10px;
}

.mylunches-ad-banner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
  align-items: center;
}

.trophies-header-challenge-yourself {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:flex-start;
  align-items: baseline;
  margin-bottom: 15px;
}

.trophies-header-challenge-yourself-laptop {
  height: 115px;
}


.challenges-group-items {
  margin: 18px 0 54px;
  display: grid;
  grid-template-columns: repeat(3, 350px);
  grid-gap: 40px;
  align-items:flex-start;
  justify-content: center;
}

.trophies-group-items {
  margin: 18px 0 54px;
  display: grid;
  grid-template-columns: repeat(5, 150px);
  grid-gap: 40px;
  align-items:flex-start;
  justify-content: center;
}

.trophies-group-profile {
  margin-top: 10px;
  margin-bottom: 10px;
}

.trophies-items-profile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
}

.trophies-img-profile {
  margin-right: 15px;
}

.giveawaypartners-group-items {
  margin: 18px 0 35px;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(6, 170px);
  grid-gap: 40px;
  align-items: center;
  justify-content: center;
}
.giveawaypartners-group-items_7logos {
  margin: 18px 0 35px;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(7, 170px);
  grid-gap: 5px;
  align-items: center;
  justify-content: center;
}
.boutique-header-bar {
  background-color: #dcc6e0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
}

.boutique-visit-amz-section {
  margin-top: 20px;
  background-color: #dcc6e0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
}

.boutique-visit-amz-section h2{
  margin-top: 15px;
  text-align: center;
}

.boutique-visit-amz-section a{
  margin-bottom: 15px;
}

.boutique-group {
  width: 100%;
}

.challenges-featured-group {
  width: 100%;
}

.challenges-group {
  width: 100%;
}

.challenge-group-items-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.challenge-group-items-header-trophy-badge {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
}

.challenges-group-items-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
}

.trophies-group {
  width: 100%;
}

.trophies-title {
  text-align: center;
  font-weight: bold;
  color: #000000;
  margin-bottom: 3px;
}

.challenge-title {
  text-align: center;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
}

.trophies-date {
  text-align: center;
  font-weight: lighter;
  color: #737373;
  margin-bottom: 5px;
}

.trophies-upload-img-profile {
  margin-right: 10px;
}

.giveaway-group {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.giveaway-group-items {
  display: flex;
  grid-template-columns: repeat(3, 186px);
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin: 18px 0 54px;
  grid-gap: 40px 100px;
}

.giveawaypartners-group {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.boutique-menus {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 10px 20px;
  margin-bottom: 10px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 85px;
  background-color: #f1f1f1;
}

.boutique-menus h3 {
  color: #737373;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.boutique-item {
  outline: none;
  width: 200px;
}

.giveaway-item {
  outline: none;
  width: 150px;
}

.boutique-item-image {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: top center;
}

.features-item-image-lunchboxcards {
  width: 300px;
  height: 300px;
  background-image: url(/images/features_lunchbox_cards.jpg);
  align-items: center;
  border-radius: 16px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 15px;
}

.features-item-image-lunchboxcards2 {
  width: 300px;
  height: 300px;
  background-image: url(/images/features_lunchbox_cards.jpg);
  border-radius: 16px;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
 
}

.community-item-image-instagram {
  width: 300px;
  height: 300px;
  background-image: url(/images/community_Teuko_Kids_lunchbox_ideas_instagram.jpg);
  align-items: center;
  border-radius: 16px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 15px;
}

.community-item-image-facebook {
  width: 300px;
  height: 300px;
  background-image: url(/images/community_Teuko_Kids_lunchbox_ideas_facebook.jpg);
  align-items: center;
  border-radius: 16px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 15px;
}

.community-item-image-twitter {
  width: 300px;
  height: 300px;
  background-image: url(/images/community_Teuko_Kids_lunchbox_ideas_twitter.jpg);
  align-items: center;
  border-radius: 16px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 15px;
}

.community-item-image-pinterest {
  width: 300px;
  height: 300px;
  background-image: url(/images/community_Teuko_Kids_lunchbox_ideas_pinterest.jpg);
  align-items: center;
  border-radius: 16px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 15px;
}

.community-item-image-youtube {
  width: 300px;
  height: 300px;
  background-image: url(/images/community_Teuko_Kids_lunchbox_ideas_youtube.jpg);
  align-items: center;
  border-radius: 16px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 15px;
}

.features-item-image-foodgroupindicators {
  width: 300px;
  height: 300px;
  background-image: url(/images/features_foodgroupindicators.jpg);
  align-items: center;
  border-radius: 16px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 15px;
}

.features-item-image-foodgroupindicators2 {
  width: 300px;
  height: 300px;
  background-image: url(/images/features_foodgroupindicators.jpg);
  border-radius: 16px;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
}

.features-item-image-schoollunchmenus {
  width: 300px;
  height: 300px;
  background-image: url(/images/features_schoollunchmenus.jpg);
  align-items: center;
  border-radius: 15px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 15px;
}

.features-item-image-schoollunchmenus2 {
  width: 300px;
  height: 300px;
  background-image: url(/images/features_schoollunchmenus.jpg);
  border-radius: 16px;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
}

.features-item-image-lunchboxdiary {
  width: 300px;
  height: 300px;
  background-image: url(/images/features_lunchboxdiary.jpg);
  align-items: center;
  border-radius: 16px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 15px;
}

.features-item-image-lunchboxdiary2 {
  width: 300px;
  height: 300px;
  background-image: url(/images/features_lunchboxdiary.jpg);
  border-radius: 16px;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
}

.features-item-image-groceryshopping {
  width: 300px;
  height: 300px;
  background-image: url(/images/features_groceryshopping.jpg);
  align-items: center;
  border-radius: 16px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 15px;
}

.features-item-image-groceryshopping2 {
  width: 300px;
  height: 300px;
  background-image: url(/images/features_groceryshopping.jpg);
  border-radius: 16px;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
}

.features-item-image-exploreteuko {
  width: 300px;
  height: 300px;
  background-image: url(/images/features_exploreteuko.jpg);
  align-items: center;
  border-radius: 16px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 15px;
}

.giveaway-item-image {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: top center;
  border: 1px solid #389477;
  border-radius: 4px;
}

.feature-searchpage-items {
  margin: 18px 0 54px;
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-gap: 40px;
  align-items: center;
  justify-content: center;
}

.feature-searchpage {
  outline: none;
  width: 200px;
}

.feature-searchpage-image {
  width: 200px;
  height: 100px;
  background-size: cover;
  background-position: top center;
  border-radius: 8px;
}

.giveawaypartners-item {
  outline: none;
  width: 170px;
}

.giveawaypartners-item-image {
  width: 150px;
  height: 50px;
  background-size: cover;
  background-position: top center;
}

.boutique-item-caption {
  margin-top: 12px;
  text-align: center;
}

.features-lunchboxcards-item-caption {
  margin-top: 12px;
  margin-bottom: 10px;
  text-align: center;
  width: 300px;
}

.community-hashtag-teuko-item-caption {
  margin-top: 12px;
  margin-bottom: 10px;
  text-align: center;
  width: 300px;
}

.features-lunchboxcards-text {
  text-align: center;
  width: 300px;
  margin-bottom: 15px;
}

.giveaway-item-caption {
  margin-top: 12px;
}

.terms-page {
  padding: 72px 0;
}

.terms-page h1 {
  margin-bottom: 36px;
}

.terms-page h3,
.terms-page h4 {
  margin: 27px 0;
}

.terms-page h5 {
  margin: 21px 0;
}

.terms-page p {
  margin-bottom: 18px;
  line-height: 23px;
}

.terms-page ul {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 30px;
  margin-bottom: 18px;
}

.terms-page li {
  display: list-item;
  line-height: 23px;
  font-size: 16px;
  font-weight: 400;
}

.terms-page a {
  cursor: pointer;
  color: #924e9f;
}
