.settings-page {
  margin-bottom: 128px;
}

.settings-page .Collapsible {
  margin: 32px 0;
  max-width: 1040px;
  border: 2px solid #934fa0;
  border-radius: 6px;
}

.settings-page .Collapsible__trigger {
  width: 100%;
  background-color: #934fa0;
  display: block;
  padding: 24px;
  box-sizing: border-box;
  cursor: pointer;
  color: white;
  font-weight: 500;
}

.Collapsible__contentInner {
  padding: 24px;
  background: white;
}

.Collapsible__contentInner input {
  border: 1px solid #b2b2b2;
  
  padding: 9px 18px;
  width: 200px;
  font-size: 18px;

  background-color: white;
  max-width: 100%;
}

.Collapsible__contentInner label {
  width: 100px;
  display: inline-block;
}

.settings-page .country-dropdown {
  display: inline-block;
  margin-left: 24px;
  z-index: 9;
}

.settings-button {
  margin-top: 48px;
  padding: 12px 32px;
  font-size: 14px;
  background-color: #934fa0;
  cursor: pointer;
  text-align: center;
  border: none;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 6px;
}

.left-settings {
  position: relative;
}

.left-settings .error-class,
.change-password-form .error-class,
.change-preference-form .error-class,
.change-notifications-form .error-class {
  top: -15px;
}

.Collapsible__contentInner {
  display: flex;
}

.right-settings {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.image-preview {
  height: 200px;
  width: 200px;
  background-color: gainsboro;
  border-radius: 50%;
  margin-bottom: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 29px;
  background-size: cover;
  background-position: top center;
}

input.prof-pic-upload-link {
  width: 270px;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;
}

.change-password-form,
.change-preference-form,
.change-notifications-form {
  position: relative;
  max-width: 100%;
}

.change-password-form label {
  width: 200px;
}

.preference-group {
  display: flex;
  align-items: top;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.preference-group input,
.change-notifications-form input {
  width: auto;
  margin: 0 12px 0 24px;
  background: none transparent;
  border: 0;
}

.preference-group p {
  width: 175px;
  text-align: center;
  line-height: 22px;
}

.change-notifications-form h5 {
  margin-bottom: 18px;
}

.delete-account-blurb {
  font-size: 16px;
}

.input-group-social {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.input-group-social-table {
  display: table;
}

.input-group-addon {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #555555;
    text-align: center;
    background-color: #eeeeee;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
}

.settings-social-input {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.form-group {
  margin-bottom: 10px;
}

.social-network-name {
  margin-left: 5px;
}
