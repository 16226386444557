@media only screen and (max-width: 1360px) {
  .in-app-container {
    width: calc(100% - 224px);
  }
}

/*desktop*/
@media only screen and (max-width: 1280px) {
  .features-list {
    padding-right: 0;
    align-items: unset;
  }
  .features-partners-list {
    padding-right: 0;
    align-items: unset;
  }

  .landing-heading-lunch-image {
    padding-right: 100px;
  }

  .a0g {
    padding-left: 12px;
    padding-right: 12px;
  }

  .lunch-and-bullets {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .lunch-image {
    position: relative;
    width: 700px;
    margin-bottom: 40px;
  }

  .lunch-group {
    grid-template-columns: 250px 250px 250px;
    grid-gap: 25px;
  }
  .lunch-block:last-of-type {
    display: none;
  }
  .home-link {
    display: none;
  }
  .search-input {
    width: 300px;
  }
  .in-header .full-menu-option {
    padding: 0 24px;
  }
  .in-app-container {
    width: calc(100% - 112px);
  }

  .lunch-thumb-group {
    grid-template-columns: 200px 200px 200px 200px;
  }
  .menu {
    overflow-x: scroll;
  }
  .category-ideas {
    overflow-x: scroll;
  }

  .category-boutique-items {
    overflow-x: scroll;
  }

  .category-giveaway-items {
    overflow-x: scroll;
  }

  .category-blog-items {
    overflow-x: scroll;
  }

  .boutique-page .standard-container {
    flex-direction: column;
  }

  .boutique-menu {
    position: inherit;
    width: 100%;
  }

  .boutique-menu-links {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .boutique-menu-link {
    margin-right: 24px;
  }

  .boutique-groups {
    padding-left: 0;
    margin-top: 24px;
  }
  .giveaway-groups {
    padding-left: 0;
    margin-top: 24px;
  }
}

/*laptop*/
@media only screen and (max-width: 1024px) {

  .landing-heading-lunch-image {
    padding-right: 100px;
  }

  .a0g {
    padding-left: 12px;
    padding-right: 12px;
  }

  .lunch-group {
    grid-template-columns: 200px 200px 200px;
    grid-gap: 50px;
  }
  .lunch-image-small {
    width: 200px;
    height: 200px;
  }
  .in-header .options-left {
    padding-left: 24px;
  }

  .in-header .options-left a {
    margin-right: 12px;
  }

  .search-input {
    width: 200px;
  }
  .in-header .full-menu-option {
    padding: 0 12px;
    font-size: 14px;
  }
  .close-lunch {
    top: 70px;
    right: 20px;
    fill: #949090;
    stroke: black;
    font-size: 24px;
    z-index: 9999;
  }
  .lunch-right {
    width: 350px;
    padding: 10px 15px;
  }

  .like-photos .like-photo:nth-child(n + 5) {
    display: none;
  }
  .story-photo {
    display: none;
  }
  .discover-photo {
    width: 100%;
  }

  .discover-small-splash-header {
    height: 300px;
    width: 300px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
  }

  .feed-ad-small-splash-header {
    height: 260px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
  }

  .preference-group p {
    width: 100%;
    margin-bottom: 12px;
  }
  .contact-section .standard-container {
    flex-direction: column;
    align-items: center;
  }

  .contact-form {
    padding-bottom: 24px;
  }

  .boutique-group-items {
    grid-template-columns: repeat(3, 186px);
    align-items: center;
    justify-content: center;
  }

  .trophies-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: baseline;
    margin-bottom: 15px;
  }

  .trophies-group-items {
    grid-template-columns: repeat(3, 150px);
    align-items: flex-start;
    justify-content: center;
  }

  .challenges-group-items {
    grid-template-columns: repeat(1, 350px);
    align-items: flex-start;
    justify-content: center;
  }

  .challenges-featured-banner {
    width: 100%;
  }

  .challenges-featured-details {
    flex-wrap: wrap;
    width: 100%;
  }

  .challenges-featured-trophy-badge {
    margin: auto;
    margin-top: 15px;
  }

  .challenges-featured-text-title h1 {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .challenges-featured-rule-short {
    margin-bottom: 10px;
  }

  .profile-trophy-case {
    margin-top: 30px;
    margin-left: 0px;
  }

  .giveaway-splash-header-laptop-bis {
    display: none;
  }

  .giveawaypartners-group-items {
    grid-template-columns: repeat(2, 186px);
    align-items: center;
    justify-content: center;
  }
  .giveawaypartners-group-items_7logos {
    grid-template-columns: repeat(2, 186px);
    align-items: center;
    justify-content: center;
    grid-gap: 40px;
  }

  .boutique-item {
    width: 150px;
  }

  .giveaway-item {
    width: 150px;
  }

  .boutique-group {
    margin: auto;
  }
  .boutique-header-bar {
    margin: auto;
    padding: 0;
  }
  .boutique-item-image {
    width: 186px;
    height: 186px;
    justify-content: center;
    margin: auto;
  }

  .giveaway-item-image {
    width: 150px;
    height: 150px;
  }

  .feature-searchpage-items {
    grid-template-columns: repeat(2, 140px);
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
  }

  .feature-searchpage {
    width: 140px;
  }

  .feature-searchpage-image {
    width: 120px;
    height: 60px;
    margin-left: 10px;
  }

  .giveawaypartners-item {
    width: 145px;
  }

  .giveawaypartners-item-image {
    width: 145px;
    height: 50px;
  }
}

@media only screen and (max-width: 965px) {
  .lunch-thumb-group {
    grid-template-columns: 200px 200px 200px;
  }
}

@media only screen and (max-width: 865px) {
  .lunch-popup {
    flex-direction: column;
    width: 480px;
    height: unset;
    margin: 36px 0;
  }
  .lunch-left {
    min-height: 350px;
    width: 480px;
    height: 480px;
    max-width: 100%;
    /* width: 100%; */
  }
  .lunch-right {
    flex-basis: unset;
    width: 100%;
  }

  .search-title-header-ad-laptop_leaderboard {
    display: none;
  }
}

/*tablet*/
@media only screen and (max-width: 768px) {
  .lunch-block:nth-last-child(2) {
    display: none;
  }
  .lunch-group {
    grid-template-columns: 200px 200px;
  }

  .BcmU7 {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 60px;
  }

  .homepage-supporteradtext {
    align-items: flex-end;
  }

  .joinuslaptop-button {
    display: inline-block;
    margin: 36px auto;
    border-radius: 4px;
    margin-left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
  }

  .joinusipadcellphone-button {
    display: inline-block;
    margin: 36px auto;
    border-radius: 4px;
    margin-left: 50%;
    transform: translateX(-50%);
    visibility: visible;
    background-color: #f3cd00;
  }

  .joinusipadcellphone-button:hover {
    background-color: #ab9000;
  }

  .review-image img {
    width: 100px;
    height: 100px;
  }
  .feature-options {
    flex-direction: row;
    flex-wrap: wrap;
    height: unset;
  }

  .partners-logos-options {
    flex-direction: column;
    height: unset;
  }

  .featured-in {
    height: unset;
  }

  .feature-options img {
    padding: 12px;
  }
  .partners-logos-options img {
    padding: 10px;
  }
  .bottom-nav {
    display: block;
    height: 45px;
  }

  .main-header {
    display: block;
    height: 45px;
  }

  .main-footer {
    display: block;
    height: 45px;
  }

  .mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    height: 45px;
    width: 100%;
  }
  .header-standard {
    display: none;
  }
  .fs-right {
    width: 300px;
  }

  .preference-fs-right {
    width: 300px;
  }

  .preference-fs-right-search {
    width: 300px;
  }

  .search-date-box {
    width: 230px;
    margin-right: 10px;
  }

  .food-selection-group,
  .search-selection-group {
    justify-content: space-around;
  }
  .tn-group input,
  .tn-group textarea {
    width: 300px;
  }

  .titling-right {
    width: 300px;
  }

  .titling-full-group {
    justify-content: space-around;
  }

  .lunch-thumb-group {
    grid-template-columns: 200px 200px;
  }

  .lunch-main-info {
    height: 180px;
  }

  .lunch-modal .close-lunch {
    font-size: 34px;
    right: 40px;
  }

  .lunch-modal {
    overflow: scroll;
    align-items: flex-start;
  }
  .close-modal {
    font-size: 36px;
    top: 36px;
    right: 36px;
  }

  .close-modal-list {
    font-size: 36px;
  }

  .menu-action-icons svg {
    font-size: 24px;
    margin-left: 10px;
  }
  .team-member {
    width: 100%;
  }
  .Collapsible__contentInner {
    flex-wrap: wrap;
  }
  .preference-group input[type="checkbox"] {
    width: auto;
    margin-top: 10px;
  }

  .boutique-group {
    margin: auto;
  }
  .boutique-header-bar {
    margin: auto;
    padding: 0;
  }
  .boutique-group-items {
    grid-template-columns: repeat(2, 200px);
    align-items: center;
    justify-content: center;
  }
  .boutique-item-image {
    width: 200px;
    height: 200px;
    justify-content: center;
    margin: auto;
  }

  .trophies-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: baseline;
    margin-bottom: 15px;
  }

  .trophies-group-items {
    grid-template-columns: repeat(2, 150px);
    align-items: flex-start;
    justify-content: center;
  }

  .profile-trophy-case {
    margin-top: 30px;
    margin-left: 0px;
  }

  .giveaway-group-items {
    display: flex;    
    grid-template-columns: repeat(2, 150px);
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin: 18px 0 54px;
    grid-gap: 40px;
  }

  .giveaway-splash-header-laptop-bis {
    display: none;
  }

  .feature-searchpage-items {
    grid-template-columns: repeat(2, 140px);
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
  }

  .giveawaypartners-group-items {
    grid-template-columns: repeat(2, 150px);
    align-items: center;
    justify-content: center;
  }
  .giveawaypartners-group-items_7logos {
    grid-template-columns: repeat(2, 150px);
    align-items: center;
    justify-content: center;
    grid-gap: 40px;
  }
  .onboarding-plans {
    width: 100%;
    flex-wrap: wrap-reverse;
  }
}

/*landscape*/
@media only screen and (max-width: 640px) {
  .lunch-modal .lunch-popup {
    border-radius: 0;
  }
  .standard-container {
    width: 90%;
  }
  .feature-item {
    flex-direction: column;
    align-items: center;
  }

  .features-list {
    max-width: 100%;
  }
  .features-partners-list {
    max-width: 100%;
  }

  .feature-item img {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
    border-radius: 50%;
  }

  .feature-text {
    text-align: center;
    margin-left: 0;
  }

  .review-item {
    flex-direction: column;
    width: 100%;
  }
  .review-content {
    max-width: 300px;
    margin-top: 24px;
  }
  .review-image {
    margin-right: 0;
  }
  .review-image img {
    width: 100px;
    height: 100px;
  }
  .lunch-header {
    font-size: 11px;
    font-weight: 500;
  }
  .food-selection-group,
  .search-selection-group {
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
  }

  .fs-left-img-preview {
    height: 175px;
    width: 200px;
  }

  .food-group-options {
    margin: 8px;
  }

  .fs-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .food-group-option {
    margin: 0 6px;
  }

  .selected-foods-section {
    margin-top: 12px;
    height: 100px;
  }
  .create-lunch-step-two .go-back {
    margin-top: 12px;
  }

  .continue-ingredients-button {
    margin-top: 20px;
  }
  .titling-full-group {
    flex-direction: column;
    align-items: center;
  }

  .titling-left {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }

  .titling-left-img-preview {
    width: 175px;
    height: 175px;
  }

  .tn-group {
    margin-bottom: 24px;
  }

  .finsh-menu-button {
    margin-top: 12px;
  }

  .tn-group input,
  .tn-group textarea {
    font-size: 16px;
  }

  .tn-group textarea {
    height: 75px;
  }
  .in-app-container {
    width: 90%;
  }
  .profile-image-forlaptop {
    display: none;
  }

  .profile-image-forsmartphone {
    display: initial;
    width: 40px;
    height: 40px;
    margin-right: 7px;
    background-size: cover;
    background-position: top center;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    background-image: url("/images/user.png");
  }

  .profile-name-plus-link {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    justify-content: center;
    align-items: center;
  }

  .profile-name-plus-link h2 {
    white-space: pre-wrap;
    max-width: 100%;
    font-size: 24px;
    font-weight: 600;

    word-break: break-all;
    margin-top: 6px;
  }

  .profile-button {
    margin-left: 0;
    margin-top: 12px;
  }

  .profile-header {
    text-align: center;
  }

  .profile-lunchbox h5 {
    font-size: 14px;
  }

  .profile-stats {
    margin-top: 12px;
  }

  .profile-stats h2 {
    font-size: 20px;
  }
  

  .profile-header {
    align-items: flex-start;
  }
  /*.my-menus-header {
    flex-direction: column;
    text-align: center;
  } */

  .my-menus-header {
    display: flex;
    justify-content: center;
  }
  .my-categories-header {
    display: flex;
    justify-content: center;
  }

  .my-menus-dates-group {
    text-align: center;
    justify-content: center;
  }

  .left-menu-arrow {
    position: relative;
    left: unset;
    transform: unset;
  }

  .my-menu-dates-group h2 {
    font-size: 36px;
    padding: 0 12px;
  }

  

  .print-icon,
  .cart-icon,
  .stat-icon,
  .medic-icon {
    padding: 5px 5px 0;
    font-size: 16px;
    margin-left: 8px;
  }
  .heart-icon {
    padding: 12px 12px 0;
    font-size: 32px;
  }
  .shopping-list-header h3 {
    font-size: 24px;
  }
  .favlunchboxitems-list-header h3 {
    font-size: 24px;
  }

  .grocery-list-icons svg {
    font-size: 21px;
  }
  .favlunchboxitems-list-icons svg {
    font-size: 21px;
  }
  .menu-action-icons {
    display: flex;
    justify-content: center;
  }
  .question-answer-open {
    max-height: 600px;
  }
  .onboard-modal {
    width: 90%;
    padding: 60px 30px;
  }

  .onboard-modal .close-icon {
    top: 15px;
  }

  .eating-age-grid {
    grid-template-columns: 180px 180px;
  }
  .follow-name {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .close-modal {
    font-size: 28px;
    top: 14px;
    right: 14px;
  }
  .close-modal-list {
    font-size: 28px;
  }

  .pub-amazn-smartphone {
    display: initial;
  }
  .pub-banner-smartphone {
    display: initial;
    height: 250px;
    width: 300px;
    /* background-color: grey; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 35px;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
    border: 1px solid #d9d9d9;
  }
  .pub-amazn-laptop {
    display: none;
  }
  .pub-banner-laptop {
    display: none;
  }

  .search-title-header-ad-smartphone_leaderboard {
    display: initial;
    width: 320px;
    height: 50px;
    /* background-color: grey; */
  }

  .search-title-header-ad-smartphone_leaderboard2 {
    display: initial;
    width: 320px;
    height: 50px;
    /* background-color: grey; */
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .search-title-header-ad-laptop_leaderboard {
    display: none;
  }


  .giveaway-splash-header-smartphone {
    display: flex;
    height: 350px;
    width: 350px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
    top: 45px;
    margin: auto;
    background-repeat: no-repeat;
  }

  .giveaway-splash-header-laptop {
    display: none;
  }
}

/*phone*/
@media only screen and (max-width: 480px) {

  .landing-heading-lunch-image {
    padding-right: 0px;
  }

  .a0g {
    margin-top: 60px;
  }

  .xjbqb8wlogo {
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
    background-color: white;
  }

  .xjbqb8wlogoimage {
    height: 50px;
    width: 50px;
  }

  .ab8q {
    height: 250px;
    bottom: 45px;
  }

  .acps {
    margin: auto;
  }
  .aacl {
    text-align: center;
    margin: auto;
  }
  .aadf {
    text-align: center;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
  } 
  .full-menu-option-outheader-login-bannerbottom {
    margin-top: 20px;
    margin-bottom: 45px;
  }
  .full-menu-option-outheader-signup-bannerbottom {
    margin-top: 20px;
    margin-bottom: 45px;

  }

  .joinfree-button {
    display: inline;
  }

  .browse-all-button {
    width: 400px;
    box-sizing: border-box;
    margin-left: 0px;
    transform: none;
  }

  .reviews .slider-list {
    max-height: unset;
  }

  .reviews {
    margin-bottom: 20px;
  }

  .splash-header {
    height: 520px;
  }
  .slider-frame {
    padding-bottom: 72px !important;
  }

  .simple-fun-section h1,
  .landing-heading-section h1,
  .landing-features-section h1 {
    margin-top: 15px;
    font-size: 30px;
  }
  .landing-heading-section-subtitle {
    width: 300px;
    max-width: 100%;
    text-align: center;
    line-height: 1.2;
  }
  .landing-features-section-subtitle {
    width: 300px;
    max-width: 100%;
    text-align: center;
    line-height: 1.2;
  }

  .landing-heading-section-subtitle p {
    font-size: 15px;
  }
  .landing-features-section-subtitle p {
    font-size: 15px;
  }

  .simple-fun-section-landing h1 {
    margin-top: 45px;
    font-size: 36px;
  }

  .simple-fun-section {
    height: 1150px;
  }

  .footer2-nav {
    margin-bottom: 25px;
  }

  .footer2_column_title {
    padding: 25px 0 0;
  }

  .footer2_column_title_h3 {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 5px;
  }
  
  .footer2_column_list_resources_ul {
      font-size: 0.8rem;
      line-height: 1rem;
  }
  
  .footer2_column_list_resources_p {
    font-size: 0.8rem;
    line-height: 0.9rem;
    margin-bottom: 8px;
  }

  .footer2_column_list_resources_p:hover {
    color: #934fa0 !important;
  }
  
  .footer2_column_list_resources_a {
    font-size: 0.8rem;
    line-height: 0.9rem;
    margin-bottom: 8px;
    transition: color .2s;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: color;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  .footer2_column_list_resources_a a:-webkit-any-link {
    cursor: pointer;
  }

  .footer2_column_list_resources_a:hover {
    color: #934fa0 !important;;
  }

  .footer2-section_privacy_and_social {
    margin-bottom: 30px;
  }

  .footer2-section_privacy_and_social-terms_privacy_all_rights_reserved_p {
    font-size: 0.5rem;
    line-height: 1rem;
  }

  .footer2-section_privacy_and_social-terms_privacy_all_rights_reserved_span {
    font-size: 0.5rem;
    line-height: 1rem;
  }


  .in-app-container {
    width: 85%;
  }

  .landing-heading-section-textcta {
    width: 100%;
  }

  .navigation-features {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .story-content {
    width: 100%;
  }
  .about-us-page {
    width: 100%;
  }

  .lunch-and-bullets {
    min-height: 600px;
  }

  .lunch-image {
    width: 300px;
    position: absolute;
    top: 40px;
    left: auto;
  }

  .features-list {
    height: 550px;
    padding-top: 220px;
    margin-bottom: 25px;
  }
  .features-partners-list {
    height: 550px;
    padding-top: 275px;
  }

  .testimonial-partners-school {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .testimonial-partners-school-inner {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: normal;
  }

  .feature-options img {
    height: 36px;
  }

  .joinuslaptop-button {
    display: inline-block;
    margin: 36px auto;
    border-radius: 4px;
    margin-left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
  }

  .joinusipadcellphone-button {
    display: inline-block;
    margin: 36px auto;
    border-radius: 4px;
    margin-left: 50%;
    transform: translateX(-50%);
    visibility: visible;
    margin-top: 250px;
    width: 142px;
    background-color: #f3cd00;
  }

  .joinusipadcellphone-button:hover {
    background-color: #ab9000;
  }

  .lunch-group {
    grid-template-columns: 200px;
  }

  .standard-button {
    padding: 18px 21px;
    text-align: center;
  }

  .review-content {
    width: 250px;
  }

  .slider-decorator-2 button {
    top: 20px;
    margin-bottom: 30px;
  }

  .feature-options span {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .partners-logos-options span {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .subscribe h2 {
    font-size: 22px;
  }

  .footer-links {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-links a {
    padding-bottom: 12px;
  }
  /* .in-mobile-menu .company-logo {
    display: none;
  } */
  .in-mobile-menu .mobile-company-logo {
    display: flex;
  }
  .create-lunch-popup {
    width: 100%;
    height: 100%;
  }

  .lunch-header {
    font-size: 11px;
    font-weight: 500;
  }

  .continue-lunch-button,
  .continue-ingredients-button,
  .finsh-menu-button {
    box-sizing: border-box;
    border-radius: 0px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .photo-upload-zone {
    width: 250px;
    height: 250px;
  }
  .create-lunch-body {
    height: 80%;
    height: calc(100% - 60px);
    height: -moz-calc(100% - (60px));
    height: -webkit-calc(100% - (60px));
    box-sizing: border-box;
    max-height: 700px;
    overflow: scroll;
  }

  .create-lunch-step-one {
    height: 100%;
  }
  .continue-ingredients-button,
  .finsh-menu-button {
    width: 100%;
  }

  .create-lunch-step-two,
  .create-lunch-step-three {
    height: 100%;
    max-height: 700px;
  }

  .create-lunch-step-two .go-back,
  .create-lunch-step-three .go-back {
    position: absolute;
    bottom: 60px;
    margin-top: unset;
    left: 0;
    margin: 0 auto;
    right: 0;
  }

  .food-selector .Select-control,
  .food-selector .Select-control.is-open,
  .lunchboxitems-selector .Select-control,
  .lunchboxitems-selector .Select-control.is-open,
  .search-selector .Select-control,
  .search-selector .Select-control.is-open {
    font-size: 14px;
    height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg.select-search-icon {
    font-size: 16px;
  }

  .selected-foods-section h4 {
    font-size: 18px;
  }

  .selected-foods-section p {
    display: inline-flex;
    margin-right: 10px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .fs-left-img-preview,
  .titling-left-img-preview {
    height: 56px;
    width: 56px;
  }

  .fs-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .titling-full-group {
    margin-top: 20px;
  }

  .food-group-option {
    width: 28px;
    height: 28px;
  }

  .food-group-option img {
    height: 16px;
  }

  .selected-foods-section p {
    margin-bottom: 7px;
  }

  .selected-foods-section h4 {
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .lunch-thumb-group {
    grid-template-columns: 200px;
    justify-content: center;
  }
  .create-lunch-body h2 {
    font-size: 36px;
  }
  .profile-header {
    flex-direction: column;
    align-items: center;
  }
  .my-menu-dates-group h2 {
    font-size: 24px;
  }
  .shopping-list-header h3 {
    font-size: 18px;
  }
  .favlunchboxitems-list-header h3 {
    font-size: 18px;
  }

  .grocery-list-icons svg {
    margin-left: 7px;
    font-size: 16px;
  }
  .favlunchboxitems-list-icons svg {
    margin-left: 7px;
    font-size: 16px;
  }

  .close-modal-list {
    width: 22px;
    height: 22px;
  }

  .close-follow {
    font-size: 28px;
    top: 36px;
    right: 20px;
  }
  .team-members {
    flex-direction: column;
  }

  .team-member {
    margin-bottom: 24px;
  }

  .story-content h4 {
    text-align: center;
  }
  .Collapsible__contentInner input {
    margin-left: 0;
    width: 200px;
  }

  .settings-page .country-dropdown {
    margin-left: 0 !important;
  }

  .prof-pic-upload-link {
    width: 100px;
  }

  .right-settings {
    margin-top: 24px;
  }

  .preference-group input[type="checkbox"] {
    width: auto;
    margin-top: 10px;
  }

  .preference-group {
    flex-direction: column;
  }

  .contact-form {
    display: block;
    max-width: 100%;
  }

  .boutique-group {
    margin: auto;
  }
  .boutique-header-bar {
    margin: auto;
    padding: 0;
  }
  .boutique-group-items {
    grid-template-columns: repeat(2, 200px);
    align-items: center;
    justify-content: center;
  }
  .boutique-item-image {
    width: 200px;
    height: 200px;
    justify-content: center;
    margin: auto;
  }

  .trophies-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: baseline;
    margin-bottom: 15px;
  }

  .trophies-group-items {
    grid-template-columns: repeat(2, 150px);
    align-items: flex-start;
    justify-content: center;
  }

  .profile-trophy-case {
    margin-top: 30px;
    margin-left: 0px;
  }

  .giveaway-group-items {
    display: flex;
    grid-template-columns: repeat(1, 200px);
    justify-content: center;
  }

  .giveaway-splash-header-laptop-bis{
    display:none;
  }

  .feature-searchpage-items {
    grid-template-columns: repeat(2, 140px);
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
  }

  .giveawaypartners-group-items {
    grid-template-columns: repeat(1, 150px);
    align-items: center;
    justify-content: center;
  }
  .giveawaypartners-group-items_7logos {
    grid-template-columns: repeat(1, 150px);
    align-items: center;
    justify-content: center;
    grid-gap: 40px;
  }
  .ea-block {
    font-size: 24px;
  }

  .onboard-modal {
    width: 100%;
    height: 100%;
  }
  .eating-age-grid {
    grid-template-columns: 130px 130px;
    grid-gap: 16px;
  }
  .popup-modal {
    padding: 30px;
  }
  .lunch-popup {
    width: 100%;
    max-width: 100%;
    overflow: scroll;
  }
  .lunch-left {
    height: 100vw;
  }
  .flatpickr-calendar.open {
    left: 0 !important;
  }

  .main-footer {
    overflow: hidden;
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .footer-icons svg {
    color: #333;
    font-size: 20px;
    margin: 3px 25px 2px 25px;
  }

  .bottom-nav {
    overflow: hidden;
    background-color: rgb(220, 198, 224);
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .browse-page h1 {
    text-align: center;
    margin-top: 30px;
  }
  .discover-page h1 {
    text-align: center;
    margin-left: 0px;
  }
  .explore-food-page h1 {
    text-align: center;
    margin-bottom: 10px;
  }
  .my-lunches-page h1 {
    text-align: center;
  }
  .my-lunches-page h3 {
    text-align: center;
  }
  .boutique-page h1 {
    margin-left: 0px;
    text-align: center;
  }
  .menu {
    margin-bottom: 0px;
    justify-content: flex-start;
  }
  .category-ideas {
    margin-bottom: 0px;
  }

  .category-boutique-items {
    margin-bottom: 0px;
  }

  .category-giveaway-items {
    margin-bottom: 0px;
  }

  .category-blog-items {
    margin-bottom: 0px;
  }

  .my-lunches-filters h5,
  .browse-filters h5,
  .blog-posts-filters h5,
  .blog-filters h5 {
    margin-right: 5px;
    padding: 8px 6px;
  }

  .my-boutique-filters h5 {
    margin-right: 5px;
    padding: 8px 6px;
  }

  .my-blog-filters h5 {
    margin-right: 5px;
    padding: 8px 6px;
  }

  .my-feed-features h5 {
    margin-right: 5px;
    padding: 8px 6px;
  }

  .more-dropdown {
    left: 30%;
    width: 140px;
  }

  .lunch-placeholder {
    height: 104px;
    font-size: 12px;
    line-height: 17px;
    font-weight: 300;
  }

  .ideas-placeholder {
    font-size: 12px;
    line-height: 17px;
    font-weight: 300;
  }

  .menu-lunches {
    grid-template-columns: 104px 104px 104px 104px 104px;
  }

  .menu-lunches .lunch-thumb {
    height: 104px;
  }

  .ideas-lunches .ideas-thumb {
    height: 200px;
  }

  .menu-dates {
    display: grid;
    grid-template-columns: 104px 104px 104px 104px 104px;
    width: 532px;
    height: 30px;
  }
  .menu-dates span {
    font-size: 12px;
  }

  .menu {
    margin: 15px 0 20px;
    min-height: 140px;
  }
  .category-ideas {
    margin: 15px 0 20px;
    min-height: 140px;
  }

  .pub-amazn-smartphone {
    display: initial;
  }
  .pub-banner-smartphone {
    display: initial;
    height: 250px;
    width: 300px;
    /* background-color: grey; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 35px;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
    border: 1px solid #d9d9d9;
  }
  .pub-amazn-laptop {
    display: none;
  }
  .pub-banner-laptop {
    display: none;
  }

  .giveaway-splash-header-smartphone {
    display: flex;
    height: 350px;
    width: 350px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
    top: 45px;
    margin: auto;
  }

  .giveaway-splash-header-laptop {
    display: none;
  }

  .sign-up-options {
    margin-top: 10px;
  }
  .facebook-button {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .popup-modal h3 {
    margin-bottom: 22px;
  }
  .full-input {
    padding: 10px 20px;
  }
  .mobile-menu .mobile-company-logo {
    justify-content: left;
    padding-left: 15px;
  }

  .my-categories-explore-search-tip-boutique-laptop {
    display: none;
  }

  

  .my-categories-explore-search-tip-boutique-smartphone {
    margin-top: 25px;
    overflow-x: scroll;
  }
  .my-categories-header-smartphone {
    display: flex;
    justify-content: center;
  }

  .browse-page-header {
    top: 45px;
  }

  .challenges-page-header {
    position: -webkit-relative; /* Safari */
    position: relative;
    top: 0px;
  }

  .landing-heading-lunch-image {
    width: 350px;
    margin-top: 15px;
  }
  .landing-features-lunch-image {
    width: 350px;
    margin: 0;
  }

  .landing-features-lunch-image2 {
    width: 350px;
  }

  .profile-btn-editorfollow-laptop {
    display: none;
  }
  .profile-btn-editorfollow-phones {
    display: flex;
    justify-content: center;;
  }
  .profile-other-details{
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
  }
  .profile-social-icon {
    font-size: 22px;
  }
  .social-network-name {
    margin-bottom: 5px;
  }
  .profile-stats {
    justify-content: center;
    }
  .boutique-nodisplaysmartphone {
      display: none;
    }

    .lunch-thumb-group-display-only-smartphone {
      display: initial;
    }

    .profile-content-options-display-smartphone {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #e0e0e0;
      padding-left: 6px;
      padding-top: 5px;
      margin-top: 20px;
      height: 30px;
      background-color: #f6eff7;
  }

  .profile-content-options-display-smartphone h3 {
    margin-right: 20px;
    cursor: pointer;
    position: relative;
    top: 3px;
  }

  .profile-lunches {
    margin-top: 15px;
  }

  .lunch-grid-image-for-profile {
    width: 100px;
    height: 100px;
    border-radius: 23px;
    border: 2px solid #f5f5f5;
    cursor: pointer;
    position: relative;
    outline: none;
    background-position: top center;
    background-size: cover;
    display: block;
  }

  .lunch-thumb-group-display-only-laptop {
    display: none;
  }
  .my-lunches-header {
    justify-content: center;
  }

  .profile-image-name-smartphone {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .profile-details {
    margin-left: 0px;
  }

  .boutique-content-options {
    gap: 10px;
  }
  .boutique-content-options h3 {
    font-size: 18px;
  }
  .boutique-menus {
    gap: 1px 10px;
  }

  .video_demo_teuko_feed_on_landing_page {
    width: 333px;
    height: 166px;
  }

}

/*Iphone 12 pro*/
@media only screen and (max-width: 400px) {
  .footer-icons svg {
    color: #333;
    font-size: 20px;
    margin: 3px 23px 2px 23px;
  }

  .boutique-group {
    margin: auto;
  }
  .boutique-header-bar {
    margin: auto;
    padding: 0;
  }
  .boutique-group-items {
    grid-template-columns: repeat(2, 150px);
    align-items: center;
    justify-content: center;
  }
  .boutique-item-image {
    width: 150px;
    height: 150px;
    justify-content: center;
    margin: auto;
  }

  .profile-image-name-smartphone {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .profile-details {
    margin-left: 0px;
  }

  .boutique-content-options {
    gap: 10px;
  }
  .boutique-content-options h3 {
    font-size: 18px;
  }
  .boutique-menus {
    gap: 1px 10px;
  }
}

/* Samsung Iphone 678X w375 */
@media only screen and (max-width: 380px) {

  .landing-heading-lunch-image {
    padding-right: 0px;
  }

  .a0g {
    margin-top: 60px;
  }

  .joinfree-button {
    display: inline;
  }

  .browse-all-button {
    width: 300px;
    box-sizing: border-box;
    margin-left: 0px;
    transform: none;
  }

  .footer-icons svg {
    color: #333;
    font-size: 20px;
    margin: 3px 21px 2px 21px;
  }

  .fs-left-img-preview,
  .titling-left-img-preview {
    height: 56px;
    width: 56px;
  }

  .fs-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .simple-fun-section h1,
  .landing-heading-section h1,
  .landing-features-section h1 {
    margin-top: 15px;
    font-size: 30px;
  }
  .landing-heading-section-subtitle {
    width: 300px;
    max-width: 100%;
    text-align: center;
    line-height: 1.2;
  }
  .landing-features-section-subtitle {
    width: 300px;
    max-width: 100%;
    text-align: center;
    line-height: 1.2;
  }

  .landing-heading-section-subtitle p {
    font-size: 15px;
  }
  .landing-features-section-subtitle p {
    font-size: 15px;
  }

  .simple-fun-section-landing h1 {
    margin-top: 45px;
    font-size: 40px;
  }

  .lunch-placeholder {
    height: 104px;
    font-size: 12px;
    line-height: 17px;
    font-weight: 300;
  }

  .ideas-placeholder {
    font-size: 12px;
    line-height: 17px;
    font-weight: 300;
  }

  .menu-lunches {
    grid-template-columns: 104px 104px 104px 104px 104px;
  }

  .menu-lunches .lunch-thumb {
    height: 104px;
  }

  .ideas-lunches .ideas-thumb {
    height: 200px;
  }

  .menu-dates {
    display: grid;
    grid-template-columns: 104px 104px 104px 104px 104px;
    width: 532px;
    height: 30px;
  }
  .menu-dates span {
    font-size: 12px;
  }

  .menu {
    margin: 15px 0 20px;
    min-height: 140px;
  }
  .category-ideas {
    margin: 15px 0 20px;
    min-height: 140px;
  }

  .partners-contact-section {
    margin-top: 35px;
  }

  .pub-amazn-smartphone {
    display: initial;
  }
  .pub-banner-smartphone {
    display: initial;
    height: 250px;
    width: 300px;
    /* background-color: grey; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 35px;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
    border: 1px solid #d9d9d9;
  }
  .pub-amazn-laptop {
    display: none;
  }
  .pub-banner-laptop {
    display: none;
  }

  .giveaway-splash-header-smartphone {
    display: flex;
    height: 350px;
    width: 350px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
    top: 45px;
    margin: auto;
  }
  .giveaway-splash-header-laptop {
    display: none;
  }

  .sign-up-options {
    margin-top: 10px;
  }
  .facebook-button {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .popup-modal h3 {
    margin-bottom: 22px;
  }
  .full-input {
    padding: 10px 20px;
  }
  .mobile-menu .mobile-company-logo {
    justify-content: left;
    padding-left: 15px;
  }

  .my-categories-explore-search-tip-boutique-laptop {
    display: none;
  }

  

  .my-categories-explore-search-tip-boutique-smartphone {
    margin-top: 25px;
    overflow-x: scroll;
  }
  .my-categories-header-smartphone {
    display: flex;
    justify-content: center;
  }

  .browse-page-header {
    top: 45px;
  }
  .landing-heading-lunch-image {
    width: 300px;
  }

  .landing-features-lunch-image {
    width: 300px;
    margin: 0;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    width: 375px;
  }

  .profile-btn-editorfollow-laptop {
    display: none;
  }
  .profile-btn-editorfollow-phones {
    display: flex;
    justify-content: center;;
  }
  .profile-other-details{
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
  }
  .profile-social-icon {
    font-size: 22px;
  }
  .social-network-name {
    margin-bottom: 5px;
  }
  .profile-stats {
    justify-content: center;
    }
  .boutique-nodisplaysmartphone {
      display: none;
    }

    .lunch-thumb-group-display-only-smartphone {
      display: initial;
    }

    .profile-content-options-display-smartphone {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #e0e0e0;
      padding-left: 6px;
      padding-top: 5px;
      margin-top: 20px;
      height: 30px;
      background-color: #f6eff7;
  }

  .profile-content-options-display-smartphone h3 {
    margin-right: 20px;
    cursor: pointer;
    position: relative;
    top: 3px;
  }

  .profile-lunches {
    margin-top: 15px;
  }

  .lunch-grid-image-for-profile {
    width: 100px;
    height: 100px;
    border-radius: 23px;
    border: 2px solid #f5f5f5;
    cursor: pointer;
    position: relative;
    outline: none;
    background-position: top center;
    background-size: cover;
    display: block;
  }

  .lunch-thumb-group-display-only-laptop {
    display: none;
  }
  .my-lunches-header {
    justify-content: center;
  }

  .profile-image-name-smartphone {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .profile-details {
    margin-left: 0px;
  }

  .boutique-group {
    margin: auto;
  }
  .boutique-header-bar {
    margin: auto;
    padding: 0;
  }
  .boutique-group-items {
    grid-template-columns: repeat(2, 150px);
    align-items: center;
    justify-content: center;
  }
  .boutique-item-image {
    width: 150px;
    height: 150px;
    justify-content: center;
    margin: auto;
  }

  .boutique-content-options {
    gap: 10px;
  }
  .boutique-content-options h3 {
    font-size: 15px;
  }
  .boutique-menus {
    gap: 1px 10px;
  }

}

/* Samsung S5 phone w360 */
@media only screen and (max-width: 370px) {

  .landing-heading-lunch-image {
    padding-right: 0px;
  }

  .a0g {
    margin-top: 60px;
  }

  .joinfree-button {
    display: inline;
  }

  .browse-all-button {
    width: 300px;
    box-sizing: border-box;
    margin-left: 0px;
    transform: none;
  }

  .footer-icons svg {
    color: #333;
    font-size: 20px;
    margin: 3px 20px 2px 20px;
  }

  .fs-left-img-preview,
  .titling-left-img-preview {
    height: 56px;
    width: 56px;
  }

  .fs-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .lunch-placeholder {
    height: 104px;
    font-size: 12px;
    line-height: 17px;
    font-weight: 300;
  }

  .ideas-placeholder {
    font-size: 12px;
    line-height: 17px;
    font-weight: 300;
  }

  .simple-fun-section h1,
  .landing-heading-section h1,
  .landing-features-section h1 {
    margin-top: 15px;
    font-size: 30px;
  }
  .landing-heading-section-subtitle {
    width: 300px;
    max-width: 100%;
    text-align: center;
    line-height: 1.2;
  }
  .landing-features-section-subtitle {
    width: 300px;
    max-width: 100%;
    text-align: center;
    line-height: 1.2;
  }
  .landing-heading-section-subtitle p {
    font-size: 15px;
  }
  .landing-features-section-subtitle p {
    font-size: 15px;
  }

  .simple-fun-section-landing h1 {
    margin-top: 45px;
    font-size: 40px;
  }

  .menu-lunches {
    grid-template-columns: 104px 104px 104px 104px 104px;
  }

  .menu-lunches .lunch-thumb {
    height: 104px;
  }

  .ideas-lunches .ideas-thumb {
    height: 200px;
  }

  .menu-dates {
    display: grid;
    grid-template-columns: 104px 104px 104px 104px 104px;
    width: 532px;
    height: 30px;
  }
  .menu-dates span {
    font-size: 12px;
  }

  .menu {
    margin: 15px 0 20px;
    min-height: 140px;
  }
  .category-ideas {
    margin: 15px 0 20px;
    min-height: 140px;
  }

  .partners-contact-section {
    margin-top: 35px;
  }

  .pub-amazn-smartphone {
    display: initial;
  }
  .pub-banner-smartphone {
    display: initial;
    height: 250px;
    width: 300px;
    /* background-color: grey; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 35px;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
    border: 1px solid #d9d9d9;
  }
  .pub-amazn-laptop {
    display: none;
  }
  .pub-banner-laptop {
    display: none;
  }

  .giveaway-splash-header-smartphone {
    display: flex;
    height: 350px;
    width: 350px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
    top: 45px;
    margin: auto;
  }
  .giveaway-splash-header-laptop {
    display: none;
  }

  .sign-up-options {
    margin-top: 10px;
  }
  .facebook-button {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .popup-modal h3 {
    margin-bottom: 22px;
  }
  .full-input {
    padding: 10px 20px;
  }
  .mobile-menu .mobile-company-logo {
    justify-content: left;
    padding-left: 15px;
  }

  .my-categories-explore-search-tip-boutique-laptop {
    display: none;
  }

  

  .my-categories-explore-search-tip-boutique-smartphone {
    margin-top: 25px;
    overflow-x: scroll;
  }
  .my-categories-header-smartphone {
    display: flex;
    justify-content: center;
  }

  .browse-page-header {
    top: 45px;
  }

  .landing-heading-lunch-image {
    width: 300px;
  }

  .landing-features-lunch-image {
    width: 300px;
    margin:0;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    width: 360px;
  }

  .profile-btn-editorfollow-laptop {
    display: none;
  }
  .profile-btn-editorfollow-phones {
    display: flex;
    justify-content: center;;
  }
  .profile-other-details{
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
  }
  .profile-social-icon {
    font-size: 22px;
  }
  .social-network-name {
    margin-bottom: 5px;
  }
  .profile-stats {
    justify-content: center;
    }
    .boutique-nodisplaysmartphone {
      display: none;
    }

    .lunch-thumb-group-display-only-smartphone {
      display: initial;
    }

    .profile-content-options-display-smartphone {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #e0e0e0;
      padding-left: 6px;
      padding-top: 5px;
      margin-top: 20px;
      height: 30px;
      background-color: #f6eff7;
  }

  .profile-content-options-display-smartphone h3 {
    margin-right: 20px;
    cursor: pointer;
    position: relative;
    top: 3px;
  }

  .profile-lunches {
    margin-top: 15px;
  }

  .lunch-grid-image-for-profile {
    width: 100px;
    height: 100px;
    border-radius: 23px;
    border: 2px solid #f5f5f5;
    cursor: pointer;
    position: relative;
    outline: none;
    background-position: top center;
    background-size: cover;
    display: block;
  }

  .lunch-thumb-group-display-only-laptop {
    display: none;
  }
  .my-lunches-header {
    justify-content: center;
  }

  .profile-image-name-smartphone {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .profile-details {
    margin-left: 0px;
  }

  .boutique-group {
    margin: auto;
  }
  .boutique-header-bar {
    margin: auto;
    padding: 0;
  }
  .boutique-group-items {
    grid-template-columns: repeat(2, 150px);
    align-items: center;
    justify-content: center;
  }
  .boutique-item-image {
    width: 150px;
    height: 150px;
    justify-content: center;
    margin: auto;
  }

  .boutique-content-options {
    gap: 10px;
  }
  .boutique-content-options h3 {
    font-size: 15px;
  }
  .boutique-menus {
    gap: 1px 10px;
  }

}

/* tiny phone */
@media only screen and (max-width: 359px) {

  .landing-heading-lunch-image {
    padding-right: 0px;
  }

  .a0g {
    margin-top: 60px;
  }

  .joinfree-button {
    display: inline;
  }

  .browse-all-button {
    width: 300px;
    box-sizing: border-box;
    margin-left: 0px;
    transform: none;
  }

  .create-lunch-header {
    height: 40px;
  }

  .create-lunch-body {
    padding: 20px 0 0;
    height: 50%;
    height: calc(100% - 40px);
    height: -moz-calc(100% - (40px));
    height: -webkit-calc(100% - (40px));
    overflow: scroll;
  }

  .close-lunch {
    top: 25px;
    right: 10px;
  }

  .create-lunch-body h2 {
    font-size: 28px;
  }

  .food-selection-group,
  .search-selection-group {
    margin-top: 12px;
  }

  .fs-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .fs-left-img-preview,
  .titling-left-img-preview {
    height: 56px;
    width: 56px;
  }

  .food-group-options {
    flex-wrap: wrap;
    width: 100px;
  }

  .food-group-option {
    margin: 3px 0;
  }

  .simple-fun-section h1,
  .landing-heading-section h1,
  .landing-features-section h1 {
    margin-top: 15px;
    font-size: 36px;
  }
  .landing-heading-section-subtitle {
    width: 300px;
    max-width: 100%;
    text-align: center;
    line-height: 1.2;
  }
  .landing-features-section-subtitle {
    width: 300px;
    max-width: 100%;
    text-align: center;
    line-height: 1.2;
  }

  .landing-heading-section-subtitle p {
    font-size: 15px;
  }
  .landing-features-section-subtitle p {
    font-size: 15px;
  }

  .simple-fun-section-landing h1 {
    margin-top: 45px;
    font-size: 40px;
  }

  .titling-left-img-preview {
    height: 50px;
    width: 50px;
  }

  .footer-icons svg {
    color: #333;
    font-size: 20px;
    margin: 3px 13px 2px 13px;
  }
  .footer-icons-text {
    font-size: 8px;
  }

  .lunch-placeholder {
    height: 104px;
    font-size: 12px;
    line-height: 17px;
    font-weight: 300;
  }

  .ideas-placeholder {
    font-size: 12px;
    line-height: 17px;
    font-weight: 300;
  }

  .menu-lunches {
    grid-template-columns: 104px 104px 104px 104px 104px;
  }

  .menu-lunches .lunch-thumb {
    height: 104px;
  }

  .ideas-lunches .ideas-thumb {
    height: 200px;
  }

  .menu-dates {
    display: grid;
    grid-template-columns: 104px 104px 104px 104px 104px;
    width: 532px;
    height: 30px;
  }

  .menu-dates span {
    font-size: 12px;
  }

  .ideas-header {
    display: grid;
    grid-template-columns: 104px 104px 104px 104px 104px 104px 104px 104px 104px 104px;
    width: 1064px;
  }

  .ideas-footer {
    display: grid;
    grid-template-columns: 104px 104px 104px 104px 104px 104px 104px 104px 104px 104px;
    width: 1064px;
  }

  .menu {
    margin: 15px 0 20px;
    min-height: 140px;
  }
  .category-ideas {
    margin: 15px 0 20px;
    min-height: 140px;
  }

  .partners-contact-section {
    margin-top: 35px;
  }

  .pub-amazn-smartphone {
    display: initial;
  }
  .pub-banner-smartphone {
    display: initial;
    height: 250px;
    width: 300px;
    /* background-color: grey; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 35px;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
    border: 1px solid #d9d9d9;
  }
  .pub-amazn-laptop {
    display: none;
  }
  .pub-banner-laptop {
    display: none;
  }

  .giveaway-splash-header-smartphone {
    display: flex;
    height: 350px;
    width: 350px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-size: cover;
    background-position: top center;
    position: relative;
    top: 45px;
    margin: auto;
  }
  .giveaway-splash-header-laptop {
    display: none;
  }

  .sign-up-options {
    margin-top: 10px;
  }
  .facebook-button {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .popup-modal h3 {
    margin-bottom: 22px;
  }
  .full-input {
    padding: 10px 20px;
  }
  .mobile-menu .mobile-company-logo {
    justify-content: left;
    padding-left: 15px;
  }

  .my-categories-explore-search-tip-boutique-laptop {
    display: none;
  }



  .my-categories-explore-search-tip-boutique-smartphone {
    margin-top: 25px;
    overflow-x: scroll;
  }

  .my-categories-header-smartphone {
    display: flex;
    justify-content: center;
  }

  .browse-page-header {
    top: 45px;
  }

  .landing-heading-lunch-image {
    width: 300px;
  }

  .landing-features-lunch-image {
    width: 300px;
    margin:0;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    width: 360px;
  }

  .profile-btn-editorfollow-laptop {
    display: none;
  }
  .profile-btn-editorfollow-phones {
    display: flex;
    justify-content: center;;
  }
  .profile-other-details{
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
  }
  .profile-social-icon {
    font-size: 22px;
  }
  .social-network-name {
    margin-bottom: 5px;
  }
  .profile-stats {
    justify-content: center;
    }
    .boutique-nodisplaysmartphone {
      display: none;
    }

    .lunch-thumb-group-display-only-smartphone {
      display: initial;
    }

    .profile-content-options-display-smartphone {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #e0e0e0;
      padding-left: 6px;
      padding-top: 5px;
      margin-top: 20px;
      height: 30px;
      background-color: #f6eff7;
  }

  .profile-content-options-display-smartphone h3 {
    margin-right: 20px;
    cursor: pointer;
    position: relative;
    top: 3px;
  }

  .profile-lunches {
    margin-top: 15px;
  }

  .lunch-grid-image-for-profile {
    width: 100px;
    height: 100px;
    border-radius: 23px;
    border: 2px solid #f5f5f5;
    cursor: pointer;
    position: relative;
    outline: none;
    background-position: top center;
    background-size: cover;
    display: block;
  }

  .lunch-thumb-group-display-only-laptop {
    display: none;
  }
  .my-lunches-header {
    justify-content: center;
  }

  .profile-image-name-smartphone {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .profile-details {
    margin-left: 0px;
  }

  .boutique-group {
    margin: auto;
  }
  .boutique-header-bar {
    margin: auto;
    padding: 0;
  }
  .boutique-group-items {
    grid-template-columns: repeat(2, 140px);
    align-items: center;
    justify-content: center;
  }
  .boutique-item-image {
    width: 140px;
    height: 140px;
    justify-content: center;
    margin: auto;
  }

  .boutique-content-options {
    gap: 10px;
  }
  .boutique-content-options h3 {
    font-size: 15px;
  }
  .boutique-menus {
    gap: 1px 10px;
  }


}
/* very tiny phone */
@media only screen and (max-width: 320px) {

  .home-page{
    width: 100%;
  }

.mobile-menu {
  position: fixed;
  top: 0;
  width: 320px;
}

.landing-heading-lunch-image {
  width: 250px;
}

.landing-features-lunch-image {
  width: 250px;
  margin:0;
}

.profile-btn-editorfollow-laptop {
  display: none;
}
.profile-btn-editorfollow-phones {
  display: flex;
  justify-content: center;;
}
.profile-other-details{
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}
.profile-social-icon {
  font-size: 22px;
}
.social-network-name {
  margin-bottom: 5px;
}
.profile-stats {
  justify-content: center;
  }
  .boutique-nodisplaysmartphone {
    display: none;
  }

  .lunch-thumb-group-display-only-smartphone {
    display: initial;
  }

  .profile-content-options-display-smartphone {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
    padding-left: 6px;
    padding-top: 5px;
    margin-top: 20px;
    height: 30px;
    background-color: #f6eff7;
}

.profile-content-options-display-smartphone h3 {
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.profile-lunches {
  margin-top: 15px;
}

.lunch-grid-image-for-profile {
  width: 100px;
  height: 100px;
  border-radius: 23px;
  border: 2px solid #f5f5f5;
  cursor: pointer;
  position: relative;
  outline: none;
  background-position: top center;
  background-size: cover;
  display: block;
}
.lunch-thumb-group-display-only-laptop {
  display: none;
}
.my-lunches-header {
  justify-content: center;
}

.profile-image-name-smartphone {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.profile-details {
  margin-left: 0px;
}

.boutique-group {
  margin: auto;
}
.boutique-header-bar {
  margin: auto;
  padding: 0;
}
.boutique-group-items {
  grid-template-columns: repeat(2, 140px);
  align-items: center;
  justify-content: center;
}
.boutique-item-image {
  width: 140px;
  height: 140px;
  justify-content: center;
  margin: auto;
}

.boutique-content-options {
  gap: 10px;
}
.boutique-content-options h3 {
  font-size: 15px;
}
.boutique-menus {
  gap: 1px 10px;
}

}

/* very tiny phone Galaxy Fold */
@media only screen and (max-width: 280px) {

  .home-page{
    width: 100%;
  }

.mobile-menu {
  position: fixed;
  top: 0;
  width: 280px;
}

.landing-heading-lunch-image {
  width: 250px;
}

.landing-features-lunch-image {
  width: 250px;
  margin: 0;
}

.category-ideas-landing-smartphone {
  min-height: 210px;
}
.landing-explore-grid{
  grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px;
  height: 200px;
  grid-gap: 0px 10px;
    width: 3140px;
}

.infocard-landing {
  width: 200px;
}
.infocard-landing-image {
  width: 200px;
  height: 200px;
}
.landing-heading-section-subtitle,
.landing-features-section-subtitle {
width: 250px;
}

.browse-all-button {
  width: 200px;
}

.profile-btn-editorfollow-laptop {
  display: none;
}

.profile-btn-editorfollow-phones {
  display: flex;
  justify-content: center;;
}
.profile-other-details{
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}
.profile-social-icon {
  font-size: 22px;
}
.social-network-name {
  margin-bottom: 5px;
}
.profile-stats {
justify-content: center;
}

.boutique-nodisplaysmartphone {
  display: none;
}

.lunch-thumb-group-display-only-smartphone {
  display: initial;
}

.profile-content-options-display-smartphone {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
    padding-left: 6px;
    padding-top: 5px;
    margin-top: 20px;
    height: 30px;
    background-color: #f6eff7;
}

.profile-content-options-display-smartphone h3 {
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.profile-lunches {
  margin-top: 15px;
}

.lunch-grid-image-for-profile {
  width: 100px;
  height: 100px;
  border-radius: 23px;
  border: 2px solid #f5f5f5;
  cursor: pointer;
  position: relative;
  outline: none;
  background-position: top center;
  background-size: cover;
  display: block;
}

.lunch-thumb-group-display-only-laptop {
  display: none;
}

.my-lunches-header {
  justify-content: center;
}

.profile-image-name-smartphone {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.profile-details {
  margin-left: 0px;
}

.trophies-upload-img-profile {
  width: 60px;
  height: 60px;
}
.trophies-img-profile {
  width: 60px;
  height: 60px;
}

.boutique-group {
  margin: auto;
}
.boutique-header-bar {
  margin: auto;
  padding: 0;
}
.boutique-group-items {
  grid-template-columns: repeat(2, 125px);
  align-items: center;
  justify-content: center;
}
.boutique-item-image {
  width: 125px;
  height: 125px;
  justify-content: center;
  margin: auto;
}

.boutique-content-options {
  gap: 10px;
}
.boutique-content-options h3 {
  font-size: 15px;
}
.boutique-menus {
  gap: 1px 10px ;
}
.challenges-group-items {
  grid-template-columns: repeat(1, 275px);
  align-items: flex-start;
  justify-content: center;
}


}
