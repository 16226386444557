.main-header {
  position: fixed;
  z-index: 9999;
  width: 100%;
  top: 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #f5f5f5;
}

.main-footer {
  position: fixed;
  z-index: 9999;
  width: 100%;
  bottom: 0;
}

.header-standard {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: white;
}

.options-left {
  padding-left: 48px;
  height: 100%;
  display: flex;
}

.options-left span {
  font-weight: 600;
  cursor: pointer;
}

.header-standard a {
  font-weight: 600;
}

.menu-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu-logo img {
  height: 28px;
  width: auto;
}

.full-menu-option {
  padding: 0 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-menu-option-laptop-login {
  border-width: 1px;
  border-style: solid;
  border-radius: 22px;
  border-color: #934ea0;
  color: white;
  background-color: #934ea0;
  height: 30px;
  margin-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 10px;
}

.full-menu-option-laptop-signup {
  border-width: 1px;
  border-style: solid;
  border-radius: 22px;
  border-color: #934ea0;
  color: #934ea0;
  height: 30px;
  margin-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 10px;
}

.full-menu-option-outheader-login {
  padding-right: 0 5px;
  margin-right: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border-width: 1px;
  border-style: solid;
  border-radius: 22px;
  border-color: #934ea0;
  color: white;
  background-color: #934ea0;
  height: 20px;
  width: 46px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 6px;
}

.full-menu-option-outheader-login-bannerbottom {
  padding-right: 0 5px;
  margin-right: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  border-color: #934ea0;
  background-color: #934ea0;
  border-width: 1px;
  border-style: solid;
  border-radius: 22px;
  height: 22px;
  width: 66px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 15px;
}

.full-menu-option-outheader-signup {
  padding: 0 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border-width: 1px;
  border-style: solid;
  border-radius: 22px;
  border-color: #934ea0;
  color: #934ea0;
  height: 20px;
  width: 46px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 3px;
}

.full-menu-option-outheader-signup-bannerbottom {
  padding: 0 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 22px;
  border-color: #934ea0;
  color: white;
  height: 22px;
  width: 66px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 3px;
}

.filled-menu-option {
  background-color: #934ea0;
  color: white;
}

.filled-menu-option:hover {
  background-color: #9038a0;
}

.options-right {
  height: 100%;
  display: flex;
}

.options-right span {
  font-weight: 600;
  cursor: pointer;
}

.bottom-nav {
  display: none;
  position: relative;
  z-index: 99;
  background-color: rgb(220, 198, 224);
  height: 45px;
  a {
    cursor: pointer;
  }
}

.mobile-menu {
  display: none;
  position: relative;
  height: 100%;
  z-index: 99;
  background-color: white;
  a {
    cursor: pointer;
  }
}

.mobile-menu .company-logo,
.mobile-menu .mobile-company-logo {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 22px;
  color: black;
  font-weight: 600;
}

.mobile-menu .company-logo img,
.mobile-menu .mobile-company-logo img {
  height: 29px;
  width: auto;
}

.mobile-menu .search-header-section {
  justify-content: flex-end;
  height: 100%;
  position: relative;
}

.bm-item-list a {
  font-family: "GT Pressura Pro";
  font-size: 36px !important;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 18px;
  top: 18px;
  cursor: pointer;
  button {
    cursor: pointer;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: transparent;
  cursor: pointer;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  border: 2px solid #424242;
  border-radius: 50%;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #424242;
}

/* General sidebar styles */
.bm-menu {
  background: white;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding-top: 48px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgb(220, 198, 224);
}

.bm-item-list a {
  color: #424242;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  padding: 12px 0;
}

.bm-burger-bars {
  height: 2px !important;
}

.bm-burger-button {
  height: 18px;
  width: 21px;
  top: 50%;
  transform: translateY(-50%);
}

.options-left img {
  height: 28px;
  width: auto;
  margin-right: 24px;
}

.in-header .options-left,
.in-header .options-right {
  display: flex;
  align-items: center;
}

.in-header .options-left a {
  margin-right: 36px;
  color: #b1b1b1;
}

.active-header-option {
  color: #272727 !important;
}

.active-icon-option {
  background-color: #934ea0 !important;
}

.search-box {
  margin-right: 20px;
  position: relative;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 6px;
}

.search-input {
  width: 375px;
  font-size: 14px;
  padding: 0 50px;
  height: 41px;
  box-sizing: border-box;
  font-weight: 500;
  color: #b1b1b1;
}

.search-box svg {
  position: absolute;
  fill: #b1b1b1;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.header-profile-picture {
  width: 41px;
  height: 41px;
  display: inline-block;
  margin-right: 20px;
  background-size: cover;
  background-position: top center;
  background-image: url("/images/user.png");
  position: relative;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
}

.icon-button-notification {
  width: 41px;
  height: 41px;
  display: inline-block;
  margin-right: 20px;
  background-size: cover;
  background-position: top center;
  background-image: url("/icons/bellringingiconfornotification.gif");
  position: relative;
  cursor: pointer;
}

.icon-button-notification-seen {
  width: 41px;
  height: 41px;
  display: inline-block;
  margin-right: 20px;
  background-size: cover;
  background-position: top center;
  background-image: url("/icons/belliconnotification.jpg");
  position: relative;
  cursor: pointer;
}

.icon-button-notification-smartphone {
  width: 41px;
  height: 41px;
  display: inline-block;
  margin-right: 7px;
  background-size: cover;
  background-position: top center;
  background-image: url("/icons/bellringingiconfornotification.gif");
  position: relative;
  cursor: pointer;
}

.icon-button-notification-smartphone-seen {
  width: 41px;
  height: 41px;
  display: inline-block;
  margin-right: 7px;
  background-size: cover;
  background-position: top center;
  background-image: url("/icons/belliconnotification.jpg");
  position: relative;
  cursor: pointer;
}

.header-profile-picture-smartphone {
  width: 41px;
  height: 41px;
  display: inline-block;
  margin-right: 10px;
  background-size: cover;
  background-position: top center;
  background-image: url("/images/user.png");
  position: relative;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
}

.header-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  width: 200px;
  text-align: center;
  background-color: white;
  padding: 12px 0;
  box-shadow: 0px 0px 4px black;
  z-index: 10;
}

.header-dropdown a {
  display: block;
  padding: 12px 0;
  outline: none;
}


.header-dropdown-notification {
  position: absolute;
  top: 50px;
  right: 0;
  width: 300px;
  height: 330px;
  background-color: white;
  box-shadow: 0px 0px 4px black;
  z-index: 10;
  overflow-y: scroll;
}

.header-dropdown-notification-hr {
margin-top: 0px;
margin-bottom: 0px;
}

.header-dropdown-notification a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
}

.header-dropdown-notification-smartphone {
  position: absolute;
  top: 50px;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: white;
  padding: 12px 0;
  box-shadow: 0px 0px 4px black;
  z-index: 10;
  overflow-y: scroll;
}

.header-dropdown-notification-smartphone a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  text-align: left;
}

.header-dropdown-notification-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-left: 3px;
  width: 225px;
}

.header-dropdown-notification-hover :hover {
  background-color: #F1F1F1;
}

.header-dropdown-notification-image {
  align-self: flex-start;
  width: 50px;
  height: 50px;
  margin:auto;
}

.header-dropdown-notification-title {
  margin-top: 8px;
  margin-bottom: 3px;
}

.header-dropdown-notification-title-smartphone {
  margin-bottom: 3px;
  text-transform: none;
  font-size: 20px;
  font-weight: 800;
}

.header-dropdown-notification-text {
  font-weight: normal;
  margin-bottom: 10px;
  }

  .header-dropdown-notification-text-smartphone {
    text-transform: none;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #757575;
  }

  .header-dropdown-notification-time {
    font-size: 11px;
    color: #757575;
    font-weight: 300;
    margin-bottom: 8px;
  } 

  .header-dropdown-notification-time-smartphone {
    text-transform: none;
    font-size: 11px;
    color: #757575;
    font-weight: 300;
  } 

.mobile-right {
  display: flex;
  text-align: end;
  right: 0;
  position: absolute;
  height: 100%;
  align-items: center;
}

.mobile-search-icon {
  fill: #757575;
  font-size: 32px;
  margin-right: 15px;
  cursor: pointer;
}

.in-mobile-menu .filled-menu-option {
  padding: 0 12px;
  font-size: 14px;
  font-weight: 600;
}

.mobile-search-box {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  width: 100%;
  background-color: white;
  box-shadow: 0px 1px 1px #0000001f;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.in-mobile-menu .search-input {
  width: calc(100% - 40px);
  text-align: center;
}

.in-mobile-menu .mobile-company-logo {
  display: none;
}

@media print {
  .main-header {
    display: none;
  }
  .main-footer {
    display: none;
  }
}
