@font-face {
  font-family: "GT America Standard";
  src: local("GT America Standard"),
    url(./fonts/GT-America-Standard-Regular.eot);
  src: local("GT America Standard"),
    url(./fonts/GT-America-Standard-Regular.eot?#iefix)
      format("embedded-opentype"),
    local("GT America Standard"),
    url(./fonts/GT-America-Standard-Regular.woff2) format("woff2"),
    local("GT America Standard"),
    url(./fonts/GT-America-Standard-Regular.woff) format("woff"),
    local("GT America Standard"),
    url(./fonts/GT-America-Standard-Regular.ttf) format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "GT America Standard";
  src: local("GT America Standard"), url(./fonts/GT-America-Standard-Medium.eot);
  src: local("GT America Standard"),
    url(./fonts/GT-America-Standard-Medium.eot?#iefix)
      format("embedded-opentype"),
    local("GT America Standard"),
    url(./fonts/GT-America-Standard-Medium.woff2) format("woff2"),
    local("GT America Standard"),
    url(./fonts/GT-America-Standard-Medium.woff) format("woff"),
    local("GT America Standard"),
    url(./fonts/GT-America-Standard-Medium.ttf) format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "GT Pressura Pro";
  src: local("GT Pressura Pro"), url(./fonts/GT-Pressura-Pro-Bold.eot);
  src: local("GT Pressura Pro"),
    url(./fonts/GT-Pressura-Pro-Bold.eot?#iefix) format("embedded-opentype"),
    local("GT Pressura Pro"),
    url(./fonts/GT-Pressura-Pro-Bold.woff2) format("woff2"),
    local("GT Pressura Pro"),
    url(./fonts/GT-Pressura-Pro-Bold.woff) format("woff"),
    local("GT Pressura Pro"),
    url(./fonts/GT-Pressura-Pro-Bold.ttf) format("truetype");
  font-weight: 600;
  font-display: swap;
}

body {
  font-family: "GT America Standard", sans-serif;
  font-size: 18px;
  color: #272727;
  -webkit-font-smoothing: antialiased;
  background-color: #f5f5f5;
}

h1 {
  font-family: "GT Pressura Pro", sans-serif;
  margin-top: 20px;
  font-size: 36px;
  font-weight: 500;
}

h2 {
  font-family: "GT Pressura Pro", sans-serif;
  font-size: 24px;
  font-weight: 500;
}

h3 {
  font-family: "GT Pressura Pro", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

h4 {
  font-family: "GT America Standard", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

h5 {
  font-family: "GT America Standard", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

h6 {
  font-family: "GT America Standard", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.64px;
  text-transform: uppercase;
}

p,
a {
  font-family: "GT America Standard", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

a {
  text-decoration: none;
}

.standard-button {
  background-color: #934ea0;
  padding: 18px 64px;
  color: white;
  font-weight: 600;
  font-family: "GT America Standard", sans-serif;
  letter-spacing: 1px;
  border-radius: 4px;
  max-width: 100%;
}

.standard-button:hover {
  background-color: #88159d;
}

.giveaway-button {
  margin-top: 15px;
  margin-bottom: 15px;
}

.standard-container {
  margin: 0 auto;
  width: calc(100% - 120px);
  display: block;
  max-width: 1400px;
}

strong {
  font-weight: 600;
}

input {
  background-color: #f5f5f5;
  border-radius: 4px;
  border: none;
  padding: 15px 15px;
  outline: none;
  font-family: "GT America Standard", sans-serif;
  max-width: 100%;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bcbcbc;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  color: #bcbcbc;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  color: #bcbcbc;
}
input:-moz-placeholder {
  /* Firefox 18- */
  color: #bcbcbc;
}

textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bcbcbc;
}
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #bcbcbc;
}
textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #bcbcbc;
}
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #bcbcbc;
}

.error-class {
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  color: #f44336;
  font-size: 14px;
}

.account-details-group .error-class {
  top: -15px;
}

.in-app-container {
  width: calc(100% - 336px);
  margin: 0 auto;
  max-width: 1440px;
}
