.screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.78);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-lunch-popup {
  width: 900px;
  height: 700px;
  max-width: 100%;
  max-height: 100%;
  background-color: white;
  position: relative;
}

.create-lunch-header {
  height: 40px;
  display: flex;
}

.lunch-header {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #b1b1b1;
  font-weight: 600;
  background-color: #f0f7e5;
}

.lunch-header svg {
  margin-right: 6px;
}

.active-lunch-header {
  background-color: rgba(162, 209, 95, 0.45);
  color: #272727;
}

.create-lunch-body {
  padding: 10px 0 0;
}

.create-lunch-step-one {
  text-align: center;
  position: relative;
}

.photo-upload-zone {
  border: 1px solid #efefef;
  margin: 40px auto;
  height: 350px;
  width: 350px;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 21px;
  background-size: cover;
  background-position: top center;
  position: relative;
  cursor: pointer;
  image-orientation: 0deg;
}

.drop-active {
  background-color: #959595;
}

.drop-accept .photo-zone-desc {
  display: none;
}

.photo-zone-desc svg {
  color: #555555;
  font-size: 24px;
  margin-bottom: 12px;
}

.photo-zone-desc p {
  font-weight: 600;
  font-size: 14px;
  color: #555555;
}

.continue-lunch-button {
  width: 425px;
  display: inline-block;
  padding: 18px 0;
  background-color: #f3cd00;
  cursor: pointer;
}

.continue-lunch-button:hover {
  background-color: #ffd700 !important;
}

.image-delete {
  background-color: #e84937;
  width: 42px;
  height: 42px;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  cursor: pointer;
}

.close-lunch {
  fill: #949090;
  right: 51px;
  top: 75px;
  font-size: 48px;
  position: absolute;
  cursor: pointer;
  stroke: black;
  z-index: 9999;
}

.create-lunch-step-two h2 {
  text-align: center;
}

.create-lunch-step-two {
  width: 700px;
  margin: 0 auto;
  position: relative;
  max-width: 100%;
}

.food-selection-group {
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
}

.search-title-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.search-selection-group {
  display: flex;
  justify-content: space-between;
}

.fs-left-img-preview {
  height: 280px;
  width: 280px;
  border-radius: 21px;
  background-size: cover;
  background-position: top center;
}

.fs-right {
  width: 360px;
}

.preference-fs-right {
  width: 360px;
  display: inline-block;
  vertical-align: top;
}

.preference-fs-right p {
  margin-bottom: 2px;
  margin-top: 20px;
  font-weight: 600;
}

.preference-fs-right-search {
  width: 200px;
  margin-right: 2px;
  display: inline-block;
  vertical-align: top;
}

.preference-fs-right-search p {
  margin-bottom: 2px;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
}

.food-selector .Select-control,
.food-selector .Select-control.is-open,
.lunchboxitems-selector .Select-control,
.lunchboxitems-selector .Select-control.is-open {
  background-color: #f5f5f5 !important;
  height: 46px;
  border-radius: 8px;
  border: none;
  outline: none;
}

.search-selector {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: block;
}


.search-selector .Select-control,
.search-selector .Select-control.is-open {
  background-color: #f5f5f5 !important;
  height: 42px;
  border-radius: 8px;
  border: none;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.food-selector .Select-placeholder,
.lunchboxitems-selector .Select-placeholder,
.search-selector .Select-placeholder {
  padding-left: 10px;
  line-height: initial;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b1b1b1;
}

.food-selector .Select-arrow-zone,
.lunchboxitems-selector .Select-arrow-zone,
.search-selector .Select-arrow-zone {
  display: none;
}

.missing-food-items {
  text-align: right;
  margin-top: 2px;
  margin-bottom: 4px;
}

.missing-lunchbox-items {
  text-align: right;
  margin-top: 2px;
}

.missing-food-items p {
  color: #b9b8b8;
  font-size: 12px;
}

.missing-lunchbox-items p {
  color: #b9b8b8;
  font-size: 12px;
}

.missing-food-items a {
  color: #a5a7ee;
  font-size: 12px;
}

.missing-lunchbox-items a {
  color: #a5a7ee;
  font-size: 12px;
}

.selected-foods-section {
  margin-top: 24px;
  height: 200px;
  overflow-y: scroll;
}

.selected-foods-section h4 {
  margin-bottom: 21px;
}

.selected-foods-section p {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-foods-section svg {
  fill: #b9b8b8;
  font-size: 21px;
  cursor: pointer;
  margin-left: 4px;
}

.continue-ingredients-button {
  text-align: center;
  margin-top: 40px;
  background-color: #f3cd00;
  cursor: pointer;
}

.continue-ingredients-button:hover {
  background-color: #ffd700 !important;
}

.food-group-option img {
  height: 21px;
}

.food-group-options {
  display: flex;
  justify-content: space-around;
  margin-top: 21px;
}

.food-group-option {
  width: 37px;
  height: 37px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
}

.food-tooltip {
  position: absolute;
  top: -40px;
  font-size: 13px;
  z-index: 2499;
  background-color: #ffffff;
  padding: 7px 21px;
  border: 1px solid black;
  border-radius: 10px;
  display: none;
}

.food-group-option:hover .food-tooltip {
  display: block;
}

.food-tooltip:after,
.food-tooltip:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.food-tooltip:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #ffffff;
  border-width: 6px;
  margin-left: -6px;
}
.food-tooltip:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: black;
  border-width: 7px;
  margin-left: -7px;
}
.veggies-tooltip {
  border-color: #a2d15f !important;
}
.veggies-tooltip:before {
  border-top-color: #a2d15f;
}
.grains-tooltip {
  border-color: #f7e442 !important;
}
.grains-tooltip:before {
  border-top-color: #f7e442;
}
.protein-tooltip {
  border-color: #e84937 !important;
}
.protein-tooltip:before {
  border-top-color: #e84937;
}
.dairy-tooltip {
  border-color: #2a3daf !important;
}
.dairy-tooltip:before {
  border-top-color: #2a3daf;
}
.fruits-tooltip {
  border-color: #ea74a1 !important;
}
.fruits-tooltip:before {
  border-top-color: #ea74a1;
}
.drink-tooltip {
  border-color: #8bd3ef !important;
}
.drink-tooltip:before {
  border-top-color: #8bd3ef;
}

.food-selector .Select-input,
.lunchboxitems-selector .Select-input,
.search-selector .Select-input {
  padding-left: 52px;
  vertical-align: unset;
  height: unset;
}

.Select-input input {
  padding: 5px;
}

.food-selector .Select-multi-value-wrapper,
.lunchboxitems-selector .Select-multi-value-wrapper,
.search-selector .Select-multi-value-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.food-selector .Select-menu-outer,
.lunchboxitems-selector .Select-menu-outer {
  background-color: #f5f5f5;
  border: none;
}

.search-selector .Select-menu-outer {
  background-color: #ffffff;
  border: 1px;
}

.country-option {
  display: flex;
  height: 48px;
  background-color: #f5f5f5;
  padding: 0 12px;
  align-items: center;
  cursor: pointer;
}
.country-option label {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
}
.country-option:hover {
  background-color: #eaeaea;
}
.focused-country-option {
  background-color: #eaeaea;
}
.country-image-option {
  width: 36px;
  height: 36px;
  background-size: cover;
  background-position: top center;
  margin-right: 16px;
  border-radius: 4px;
}
.fav-items-image-option {
  width: 25px;
  height: 25px;
  background-size: cover;
  background-position: center center;
  margin-right: 10px;
  border-radius: 4px;
}

.fav-items-ids {
  vertical-align: middle;
  margin-top: 5px;
}

.search-bar-holder {
  position: relative;
}

.select-search-icon {
  fill: #b1b1b1;
  position: absolute;
  top: 50%;
  left: 18px;
  font-size: 25px;
  transform: translateY(-50%);
}

.create-lunch-step-two .go-back {
  text-align: center;
}

.create-lunch-step-three h2 {
  text-align: center;
}

.titling-full-group {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.create-lunch-step-three {
  width: 700px;
  margin: 0 auto;
  position: relative;
  max-width: 100%;
}

.titling-left {
  width: 300px;
}

.titling-left-img-preview {
  width: 300px;
  height: 300px;
  background-size: cover;
  background-position: top center;
  border-radius: 21px;
}

.titling-right {
  width: 350px;
}

.tn-group {
  margin-bottom: 50px;
  position: relative;
}

.tn-group input {
  font-size: 18px;
  display: block;
  width: 350px;
  box-sizing: border-box;
}

.word-count {
  position: absolute;
  right: 0;
  font-size: 11px;
  font-weight: 500;
  bottom: -3px;
  transform: translateY(100%);
  color: #b1b1b1;
}

.tn-group textarea {
  background-color: #f5f5f5;
  border-radius: 4px;
  border: none;
  padding: 18px 20px;
  outline: none;
  font-family: "GT America Standard", sans-serif;
  font-size: 18px;
  display: block;
  width: 350px;
  box-sizing: border-box;
  resize: none;
  height: 114px;
}

.finsh-menu-button {
  background-color: #a2d15f;
  text-align: center;
  margin-top: 90px;
  cursor: pointer;
}

.finsh-menu-button:hover {
  background-color: #7cae34 !important;
}

.create-lunch-step-three .go-back {
  text-align: center;
}
